import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { AuthenticationService } from '@core/service/authentication.service';
import { BaseComponent } from '@core/base.component';
import { StoreService } from '@core/service/store.service';
import { Store, StoreType } from '@core/dto/Store';
import { CustomizationService } from '@core/service/customization.service';
import { SearchPipe } from '@shared/pipe/search.pipe';
import {
  ColumnDefinition,
  FilterOption,
  TableRows,
} from '../../ui/table/table.component';
import { QuickAction } from '@zfb/ui/quick-actions/quick-actions.component';
import { AccessControlService } from '@core/service/access-control.service';
import { Permission } from '@core/dto/user-details';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { ColorService } from '@core/service/color.service';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['../page-shared.scss', './stores.component.css'],
})
export class StoresComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  constructor(
    private storeService: StoreService,
    protected auth: AuthenticationService,
    private customizationService: CustomizationService,
    private searchPipe: SearchPipe,
    private access: AccessControlService,
    public colorService: ColorService,
    public localeService: LocaleService
  ) {
    super(auth);
  }
  statePushedToHistory = false;

  ctaButtonText = $localize`:@@stores.ctaButton:New ${this.getStoreAlias(
    false
  )}`;

  stores: Store[] = [];
  filteredListOfStores: Store[] = [];
  searchedListOfStores: Store[] = [];
  currentPageOfStores: TableRows<Store, StoreType> = {
    getPrimaryText: (row: Store) => row.name,
    getSecondaryText: (row: Store) => this.getStoreFriendlyName(row.type),
    getStatusBarColor: (row: Store) => {
      if (row.type === 'STORE') {
        return '#00A880';
      } else {
        return '#4466EE';
      }
    },
    rows: [],
    hideMobileHeader: false,
    rowClickFunction: (it: Store) => this.open(it),
  };

  displayEmptyState = false;
  currentEmptyState: EmptyState;

  noStoresAtAllEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Smile.png',
    headingText: $localize`:@@stores.emptyState.noStoresAtAllEmptyState.headingText:Connect your user to a&nbsp;${this.getStoreAlias(
      false
    )}`,
    bodyText: $localize`:@@stores.emptyState.noStoresAtAllEmptyState.bodyText:Add ${this.getStoreAlias(
      true
    )} in order to ease organization of your users by connecting them to their respective&nbsp${this.getStoreAlias(
      false
    )}`,
    ctaButtonText: this.mayCreateNewStore()
      ? $localize`:@@stores.emptyState.noStoresAtAllEmptyState.ctaButtonText:Add a new&nbsp;${this.getStoreAlias(
          false
        )}`
      : null,
    ctaClickFunction: this.mayCreateNewStore() ? () => this.create() : null,
  };
  noStoresSearchedEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Monocle.png',
    headingText: $localize`:@@stores.emptyState.noStoresSearchedEmptyState.headingText:We couldn't find a match for&nbsp;search`,
    bodyText: $localize`:@@stores.emptyState.noStoresSearchedEmptyState.bodyText:Give it another attempt. Try&nbsp;again.`,
  };
  noStoresFilteredEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: $localize`:@@stores.emptyState.noStoresFilteredEmptyState.headingText:There is nothing here&nbsp;yet`,
  };

  typeFilters: StoreType[] = [];

  selectedStore = null;
  filterQuery = '';
  paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };

  fetchingResponse: boolean;

  modalActive = false;
  modalSmallActive = false;
  modalTitle: string;
  modalColor: string;

  createNewStore = false;
  displayStore = false;
  editStore = false;
  removeStore = false;

  defaultTypeFilters: StoreType[] =[
    StoreType.STORE,
    StoreType.E_COMMERCE,
  ];

  columns: ColumnDefinition<Store, StoreType>[] = [
    {
      text: $localize`:@@stores.columns.storeName.text:Name`,
      value: (store) => store.type,
      type: 'store-name',
      columnDataTransformer: (store) => store.name,
    },
    {
      text: 'Typ',
      columnDataTransformer: (store) => this.getStoreFriendlyName(store.type),
      filterable: true,
      filterOptions: {
        text: $localize`:@@stores.typeFilter.text:Type`,
        value: 'type',
        activeFilters: [],
        options: [
          {
            headerText: $localize`:@@stores.typeFilter.options.all.headerText:all`,
            displayText: $localize`:@@stores.typeFilter.options.all.displayText:Show all`,
            value: [],
          },
          {
            headerText: this.getStoreAlias(false),
            displayText: this.getStoreFriendlyName(StoreType.STORE),
            value: [StoreType.STORE],
          },
          {
            headerText: $localize`:@@stores.typeFilter.options.eCommerce.headerText:e-commerce`,
            displayText: $localize`:@@stores.typeFilter.options.eCommerce.displayText:E-commerce`,
            value: [StoreType.E_COMMERCE],
          },
        ],
      },
    },
  ];

  quickActions: QuickAction<Store>[] = [
    {
      text: $localize`:@@stores.quickActions.edit.text:Edit`,
      iconUrl: 'assets/icons/Icon-Edit-Box.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: () => this.access.userMay(Permission.EDIT_STORE),
      topBorder: false,
      function: (store) => this.edit(store),
    },
    {
      text: $localize`:@@stores.quickActions.remove.text:Remove`,
      iconUrl: 'assets/icons/Icon-Bin-Cerise.svg',
      textColor: this.colorService.getCeriseColorCode(),
      disabled: false,
      displayCondition: () => this.access.userMay(Permission.REMOVE_STORE),
      topBorder: false,
      function: (store) => this.openRemovalModal(store, true),
    },
  ];

  invokedFromList: boolean;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.deviceIsMobile()) {
      if (this.statePushedToHistory) {
        this.statePushedToHistory = false;
      }
      if (this.modalActive) {
        this.closeModal();
      } else if (this.modalSmallActive) {
        if (this.removeStore) {
          this.returnToViewModal();
        } else {
          this.closeSmallModal();
        }
      }
    }
  }
  ngOnDestroy(): void {
    if (this.deviceIsMobile()) {
      if (this.modalActive) {
        this.closeModal();
      } else if (this.modalSmallActive) {
        this.closeSmallModal();
      }
    }
  }

  ngOnInit() {
    if (window.location.hash === '#modal') {
      history.back();
    }
    this.setup();
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  getStoreFriendlyName(type: StoreType): string {
    if (type === StoreType.STORE) {
      return this.customizationService.getStoreAlias(false, false, false, true);
    } else {
      return $localize`:@@stores.eCommerce:E-commerce`;
    }
  }

  private setup() {
    super.clearError();
    this.fetchingResponse = true;
    this.displayEmptyState = false;
    this.typeFilters = this.defaultTypeFilters;
    this.storeService
      .get()
      .then((result) => {
        this.stores = result;
        this.paginationState.page = 1;
        this.updateListOfStores();
        this.paginationState.total = this.searchedListOfStores.length;
        this.fetchingResponse = false;
      })
      .catch((error) => {
        this.fetchingResponse = false;
        this.handleError(error);
      });
  }

  onFilterChange(event: {
    type: string;
    filterOption: FilterOption<StoreType>;
  }) {
    if (event.type === 'type') {
      this.typeFilters = event.filterOption.value.length ? event.filterOption.value : this.defaultTypeFilters;
      this.columns[1].filterOptions.activeFilters = this.typeFilters;
    }
    this.paginationState.page = 1;
    this.updateListOfStores();
  }

  updateListOfStores() {
    this.displayEmptyState = false;
    if (!this.typeFilters.length) {
      this.filteredListOfStores = this.stores;
    } else {
      this.filteredListOfStores = this.stores.filter(
        (item) => this.typeFilters.includes(item.type)
      );
    }
    this.searchedListOfStores = this.searchPipe.transform(
      this.filteredListOfStores,
      'name',
      this.filterQuery
    );

    if (!this.stores.length || !this.searchedListOfStores.length) {
      this.setEmptyState();
    }

    this.updateCurrentPageOfStores();
  }

  updateCurrentPageOfStores() {
    this.currentPageOfStores.rows = this.searchedListOfStores
      .slice(
        (this.paginationState.page - 1) * this.paginationState.pageSize,
        this.paginationState.page * this.paginationState.pageSize
      )
      .map((store) => ({
        data: store,
        quickActions: this.quickActions.filter((action) =>
          action.displayCondition(store)
        ),
      }));
    this.paginationState.numOfItems = this.currentPageOfStores.rows.length;
  }

  setEmptyState() {
    if (this.stores.length === 0) {
      // No stores exists for the merchant
      this.currentEmptyState = this.noStoresAtAllEmptyState;
    } else {
      if (this.filterQuery !== '' && !this.typeFilters.length) {
        // The user has serached, but not filtered, and there are no results
        this.currentEmptyState = this.noStoresSearchedEmptyState;
      } else if (this.typeFilters.length) {
        // The user has filtered, and no stores matches the filter
        this.currentEmptyState = this.noStoresFilteredEmptyState;
      }
    }
    this.displayEmptyState = true;
  }

  onPageChange($event) {
    this.paginationState.page = $event;
    this.updateListOfStores();
  }

  onPageSizeChange($event) {
    this.paginationState.pageSize = $event;
    this.paginationState.page = 1;
    this.updateListOfStores();
  }

  newStoreCreated() {
    this.closeModal();
    this.setup();
  }

  storeUpdated() {
    this.closeModal();
    this.setup();
  }

  create() {
    this.modalTitle = $localize`:@@stores.modal.create.title:Add ${this.getStoreAlias(
      false
    )}`;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.createNewStore = true;
    this.activateModal();
  }

  edit(store: Store) {
    this.selectedStore = store;
    this.modalTitle = $localize`:@@stores.modal.edit.title:Edit ${this.getStoreAlias(
      false
    )}`;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.displayStore = false;
    this.editStore = true;
    this.activateModal();
  }

  closeModal() {
    this.deactivateModal();
    this.createNewStore = false;
    this.displayStore = false;
    this.editStore = false;
    this.selectedStore = null;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      this.statePushedToHistory = false;
      history.back();
    }
  }

  closeSmallModal() {
    this.deactivateSmallModal();
    this.removeStore = false;
    this.selectedStore = null;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      this.statePushedToHistory = false;
      history.back();
    }
  }

  returnToViewModal() {
    this.removeStore = false;
    this.deactivateSmallModal();
    if (!this.invokedFromList) {
      this.open(this.selectedStore);
    }
    this.invokedFromList = false;
  }

  activateModal() {
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(
        null,
        null,
        `/${this.localeService.getCurrentLocaleBaseCode()}/admin/stores#modal`
      );
      this.statePushedToHistory = true;
    }
    this.modalActive = true;
  }

  deactivateModal() {
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(
        null,
        null,
        `/${this.localeService.getCurrentLocaleBaseCode()}/admin/stores#modal`
      );
      this.statePushedToHistory = true;
    }
    this.modalActive = false;
  }

  activateSmallModal() {
    this.modalSmallActive = true;
  }

  deactivateSmallModal() {
    this.modalSmallActive = false;
  }

  openRemovalModal(store: Store, invokedFromList: boolean) {
    this.selectedStore = store;
    this.invokedFromList = invokedFromList;
    this.modalTitle = $localize`:@@stores.modal.remove.title:Remove ${this.getStoreAlias(
      false
    )}`;
    this.modalColor = this.colorService.getCeriseColorCode();
    this.deactivateModal();
    this.displayStore = false;
    this.editStore = false;
    this.removeStore = true;
    this.activateSmallModal();
  }

  onStoreRemoved() {
    this.closeSmallModal();
    this.setup();
  }

  open(store: Store) {
    this.modalTitle = this.getStoreFriendlyName(store.type);
    this.modalColor = store.type === StoreType.STORE ? '#00A880' : '#4466EE';
    this.selectedStore = store;
    this.displayStore = true;
    this.activateModal();
  }

  getStoreAlias(plural: boolean): string {
    return this.customizationService.getStoreAlias(plural);
  }

  searchUpdatedQuery(event) {
    this.filterQuery = event;
    this.paginationState.page = 1;
    this.updateListOfStores();
  }

  deviceIsMobile() {
    return window.innerWidth <= 520;
  }

  mayCreateNewStore(): boolean {
    return this.access.userMay(Permission.CREATE_STORE);
  }
}
