import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Currency } from '@core/dto/CurrencyCode';
import { WidgetContext } from '../../../../projects/widget/src/components/payment-management/payment-management.interfaces';
import { Market } from '@core/dto/Market';

@Injectable()
export class PaymentCaptureService {
  constructor(private httpClient: HttpClient) {}

  private token: string;
  private apiUrl: string;
  public context = WidgetContext.STANDALONE;

  public setToken(token: string) {
    this.token = token;
  }

  public setApiUrl(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  private getHttpOptions() {
    return {
      params: {
        token: this.token,
      },
    };
  }

  public getStatus(paymentId: string): Promise<PaymentCaptureStatus> {
    return this.httpClient
      .get<PaymentCaptureStatus>(
        `${this.apiUrl}${paymentId}/status`,
        this.getHttpOptions()
      )
      .toPromise();
  }

  public captureLineItems(
    paymentId: string,
    amount: Currency,
    lineItems: LineItemDomain[]
  ): Promise<PaymentCaptureStatus> {
    const body = { captureAmount: amount, lineItems: lineItems };
    return this.httpClient
      .post<PaymentCaptureStatus>(
        `${this.apiUrl}${paymentId}/capture`,
        body,
        this.getHttpOptions()
      )
      .toPromise();
  }

  public updateLineItems(
    paymentId: string,
    amount: Currency,
    lineItems: LineItemDomain[],
    status
  ): Promise<PaymentCaptureStatus> {
    const body = {
      captureAmount: amount,
      lineItems: lineItems,
      status: status,
    };
    return this.httpClient
      .post<PaymentCaptureStatus>(
        `${this.apiUrl}${paymentId}/update`,
        body,
        this.getHttpOptions()
      )
      .toPromise();
  }

  public createRefund(refund: any): Promise<any> {
    return this.httpClient
      .post<any>(
        `${this.apiUrl}${refund.paymentRequestId}/refund`,
        refund,
        this.getHttpOptions()
      )
      .toPromise();
  }

  public approveRefund(
    paymentRequestId: string,
    refundId: string
  ): Promise<any> {
    return this.httpClient
      .post<any>(
        `${this.apiUrl}${paymentRequestId}/refund/${refundId}/approve`,
        {},
        this.getHttpOptions()
      )
      .toPromise();
  }

  public cancelRefund(
    paymentRequestId: string,
    refundId: string
  ): Promise<any> {
    return this.httpClient
      .post<any>(
        `${this.apiUrl}${paymentRequestId}/refund/${refundId}/cancel`,
        {},
        this.getHttpOptions()
      )
      .toPromise();
  }
  // always true for now, we dont have permissions in standalone widget
  isEligibleToHandlePaymentRequest(): boolean {
    return true;
  }
}

export interface LineItemDomain {
  id: string;
  merchantReference?: string;
  title: string;
  itemType: ItemType;
  description: string;
  quantity: number;
  unitPrice: number;
  taxRatePercent: number;
  totalAmount: number;
  taxAmount: number;
  captureState?: LineItemDomain[];
  refundState?: LineItemDomain[];
  merchantMetadata: string[];
  quantityUnit: string;
  deleted: boolean;
  originalState: any;
}
interface CaptureState {
  quantity: number;
  totalAmount: number;
  taxAmount: number;
  unitPrice: number;
  taxRatePercent: number;
}

export enum ItemType {
  PHYSICAL,
  DIGITAL,
  SERVICE,
  SHIPPING,
  FEE,
  DISCOUNT,
}

export interface PaymentCaptureStatus {
  id: string;
  amount: Currency;
  capturedAmount: Currency;
  availableForCapture: Currency;
  refunded: number;
  status: ZecStatus;
  merchantReference: string;
  lineItems: LineItemDomain[];
  capturedLineItems: LineItemDomain[];
  paymentMethod: string;
  pendingLineItemRefunds: WidgetRefundResponse[];
  pendingFixedAmountRefunds: WidgetFixedAmountRefunds[];
  latestCaptureTimeStamp: Date;
  fixedAmountRefunds: WidgetFixedAmountRefunds[];
  market: Market;
}
export interface WidgetFixedAmountRefunds {
  id: string;
  paymentRequestId: string;
  created: Date;
  description: string;
  taxAmount: number;
  taxRatePercent: number;
  amount: number;
}

export interface WidgetRefundResponse {
  id: string;
  paymentRequestId: string;
  created: Date;
  refundValue: Currency;
  refundLineItems: LineItemJson[];
  description: string;
}

export interface LineItemJson {
  title: string;
  quantity: number;
  unitPrice: number;
  taxRatePercent: number;
  totalAmount: number;
  taxAmount: number;
}
export interface WidgetRefundResponseVM extends WidgetRefundResponse {
  refundView: RefundView;
}

export interface WidgetFixedAmountRefundsVM extends WidgetFixedAmountRefunds {
  refundView: RefundView;
}

export enum RefundView {
  DEFAULT = 'DEFAULT',
  APPROVE = 'APPROVE',
  DENY = 'DENY',
}
export enum ZecStatus {
  CREATED = 'CREATED',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
  PENDING_MERCHANT_SIGN_OFF = 'PENDING_MERCHANT_SIGN_OFF',
  PENDING_MERCHANT_CAPTURE = 'PENDING_MERCHANT_CAPTURE', // TODO switch to this one (not ..._SIGN_OFF)
  SETTLED = 'SETTLED',
  CANCELLED = 'CANCELLED',
}
