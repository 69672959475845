<div class="card">
  <h1>Ditt konto är påväg att skapas!&nbsp;🎉</h1>
  <span class="sub-title"
    >Så fort vi har gått igenom de uppgifter som du har lämnat kommer vi att
    höra av oss till dig. Har du några frågor är du alltid välkommen att
    <a href="https://zaver.com/sv/customer-support" class="link" target="_blank"
      >kontakta&nbsp;oss.</a
    ></span
  >

  <zaver-primary-button
    [text]="'Logga ut'"
    (click)="auth.logout()"
    [color]="colorService.getCeriseColorCodeAsType()"
  >
  </zaver-primary-button>
</div>
