<ng-container *ngIf="loading">
  <zaver-loading-dots [color]="'#6A7381'"></zaver-loading-dots>
</ng-container>

<ng-container *ngIf="!loading && !!noStatusError">
  <h2>{{ noStatusError }}</h2>
</ng-container>

<div class="payment-management-wrapper" *ngIf="!loading && !noStatusError">
  <ng-container [ngSwitch]="currentView">
    <ng-container *ngSwitchCase="VIEW.ORDER_DETAILS">
      <app-order-details
        [availableForCapture]="availableForCapture"
        [initialPaymentRequestAmount]="initialPaymentRequestAmount"
        [capturedAmount]="capturedAmount"
        [setView]="setView"
        [paymentRequestStatus]="captureStatus"
        [paymentMethod]="paymentMethod"
        [showPaymentUpdate]="showPaymentUpdate"
        [slask]="slask"
        [lineItemRefunds]="lineItemRefunds"
        [fixedAmountRefunds]="fixedAmountRefunds"
        [deletedAmount]="deletedAmount"
        [latestCaptureTimeStamp]="latestCaptureTimeStamp"
        [amountLeftToCapture]="amountLeftToCapture"
        [refundedAmount]="refundedAmount"
        [unCapturedLineItems]="uncapturedLineItems"
        [capturedLineItems]="capturedLineItems"
        [onApproveRefund]="onApproveRefund"
        [onCancelRefund]="onCancelRefund"
        [inProgress]="inProgress"
        [totalRefundableAmount]="totalRefundableAmount"
        [fixedAmountRefundLineItems]="fixedAmountRefundLineItems"
        [currency]="currency"
      ></app-order-details>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.PARTIAL_CAPTURE">
      <app-widget-header
        [showError]="showError"
        [text]="'Capture payment'"
        [imgSrc]="'assets/icons/Icon-Business-Pie-Chart.svg'"
        [setView]="setView"
        [context]="context"
      ></app-widget-header>

      <div class="buttons-wrapper">
        <p>
          Amount to capture:
          {{ selectedAmountToCapture | amountPipe : currency }}
        </p>
        <div class="buttons-container">
          <zaver-secondary-button-subtle
            [text]="'Discard changes'"
            (click)="discardChanges()"
            [size]="'x-small'"
          ></zaver-secondary-button-subtle>

          <zaver-primary-button
            [text]="'Capture all selected'"
            [size]="'x-small'"
            (click)="setView(VIEW.CONFIRM_CAPTURE)"
            [disabled]="selectedLineItems.length === 0"
          ></zaver-primary-button>
        </div>
      </div>

      <div class="additional-wrapper">
        <p>
          Initial amount:
          {{ initialPaymentRequestAmount | amountPipe : currency }}
        </p>
        <div class="vertical-divider"></div>
        <p>
          Excluded from current capture:
          {{ uncapturedAmount | amountPipe : currency }}
        </p>
      </div>

      <div
        style="
          display: flex;
          margin-bottom: 16px;

          align-items: center;
        "
      >
        <p
          style="
            color: #20252d;
            font-size: 16px;
            line-height: 28px;
            margin-right: 20px;
            font-weight: 700;
          "
        >
          Awaiting capture
        </p>
        <button class="button-link" (click)="addLineItem()">
          <img
            src="assets/icons/Icon-Add-Plus-M.svg"
            style="margin-right: 8px"
          />
          Add line item
        </button>
      </div>

      <app-line-item-table
        [allChecked]="allChecked"
        [currency]="currency"
        (setAllChecked)="selectAll($event)"
        [lineItemsVM]="lineItemsVM"
        (changeQuantity)="changeQuantity($event)"
        (setTaxRatePrecent)="setTaxRatePrecent($event)"
        (setEditMode)="setEditMode($event)"
        (removeLineItem)="removeLineItem($event)"
        [currency]="currency"
        [taxRateOptions]="taxRateOptions"
      ></app-line-item-table>

      <ng-container *ngIf="capturedLineItems?.length > 0">
        <app-simple-line-item-table
          [headingText]="'Previously captured items'"
          [simpleLineItemVM]="capturedLineItems"
          [currency]="currency"
        ></app-simple-line-item-table>
      </ng-container>

      <div *ngIf="!!errorMessage" class="errorMessage">{{ errorMessage }}</div>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.PAYMENT_UPDATE">
      <app-widget-header
        [showError]="showError"
        [text]="'Edit order'"
        [imgSrc]="'assets/icons/Icon-Edit-Black.svg'"
        [setView]="setView"
        [context]="context"
      ></app-widget-header>

      <div class="buttons-wrapper">
        <p>Selected items: {{ selectedLineItems.length }}</p>
        <div class="buttons-container">
          <zaver-secondary-button-subtle
            [text]="'Discard changes'"
            (click)="discardChanges()"
            [size]="'x-small'"
          ></zaver-secondary-button-subtle>
          <zaver-secondary-button-subtle
            [text]="'Cancel order'"
            (click)="setView(VIEW.CANCEL_ORDER)"
            [size]="'x-small'"
          ></zaver-secondary-button-subtle>

          <zaver-primary-button
            [text]="'Cancel all selected'"
            [size]="'x-small'"
            (click)="setView(VIEW.PARTIAL_CANCEL_ORDER)"
            [disabled]="selectedLineItems.length === 0"
          ></zaver-primary-button>
        </div>
      </div>

      <div class="additional-wrapper">
        <p>
          Initial order amount:
          {{ initialPaymentRequestAmount | amountPipe : currency }}
        </p>
        <div class="vertical-divider"></div>
        <p>
          Amount removed from order:
          {{ selectedAmountToCapture | amountPipe : currency }}
        </p>
      </div>

      <app-line-item-table
        [allChecked]="allChecked"
        (setAllChecked)="selectAll($event)"
        [lineItemsVM]="lineItemsVM"
        (changeQuantity)="changeQuantity($event)"
        (setTaxRatePrecent)="setTaxRatePrecent($event)"
        (setEditMode)="setEditMode($event)"
        [currentView]="currentView"
        [currency]="currency"
        [taxRateOptions]="taxRateOptions"
      ></app-line-item-table>

      <ng-container *ngIf="capturedLineItems?.length > 0">
        <app-simple-line-item-table
          [headingText]="'Previously captured items'"
          [simpleLineItemVM]="capturedLineItems"
          [currency]="currency"
        ></app-simple-line-item-table>
      </ng-container>

      <div *ngIf="!!errorMessage" class="errorMessage">{{ errorMessage }}</div>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.CONFIRM_CAPTURE">
      <app-confirm-capture
        [inProgress]="inProgress"
        [onCapture]="onCapture"
        [setView]="setView"
        [selectedAmountToCapture]="selectedAmountToCapture"
        [selectedTaxAmount]="selectedTaxAmount"
        [selectedPreTaxAmount]="selectedPreTaxAmount"
        [uncapturedAmount]="uncapturedAmount"
        [currency]="currency"
      ></app-confirm-capture>
      <div *ngIf="!!errorMessage" class="errorMessage">{{ errorMessage }}</div>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.VIEW_ORDER">
      <app-view-order
        [context]="context"
        [capturedAmount]="capturedAmount"
        [availableForCapture]="availableForCapture"
        [initialPaymentRequestAmount]="initialPaymentRequestAmount"
        [inProgress]="inProgress"
        [setView]="setView"
        [uncapturedLineItems]="uncapturedLineItems"
        [capturedLineItems]="capturedLineItems"
        [removedLineItems]="deletedLineItems"
        [refundedLineItems]="refundedLineItems"
        [fixedAmountRefundLineItems]="fixedAmountRefundLineItems"
        [pendingLineItemRefundLineItems]="pendingLineItemRefundLineItems"
        [pendingFixedAmountRefundLineItems]="pendingFixedAmountRefundLineItems"
        [deletedAmount]="deletedAmount"
        [slask]="slask"
        [totalRefundableAmount]="totalRefundableAmount"
        [currency]="currency"
        [market]="market"
        [isEligibleToHandlePaymentRequest]="
          this.isEligibleToHandlePaymentRequest()
        "
      ></app-view-order>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.CANCEL_ORDER">
      <app-cancel-order
        [setView]="setView"
        [removeAllItems]="removeAllItems"
        [inProgress]="inProgress"
      ></app-cancel-order>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.PARTIAL_CANCEL_ORDER">
      <app-partial-cancel-order
        [setView]="setView"
        [selectedLineItemsVM]="selectedLineItems"
        [onUpdate]="onUpdate"
        [inProgress]="inProgress"
        [currency]="currency"
      ></app-partial-cancel-order>
      <div *ngIf="!!errorMessage" class="errorMessage">{{ errorMessage }}</div>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.REFUND_LINE_ITEM">
      <app-widget-header
        [showError]="showError"
        [text]="'Create refund'"
        [imgSrc]="'assets/icons/Icon-Reset.svg'"
        [setView]="setView"
        [context]="context"
      ></app-widget-header>

      <div class="buttons-wrapper">
        <p>
          Selected amount to refund:
          {{ selectedAmountToRefund | amountPipe : currency }}
        </p>
        <div class="buttons-container">
          <zaver-secondary-button-subtle
            [text]="'Reset changes'"
            (click)="discardChanges()"
            [size]="'x-small'"
          ></zaver-secondary-button-subtle>

          <zaver-primary-button
            [text]="'Refund selected'"
            [size]="'x-small'"
            (click)="setView(VIEW.CONFIRM_REFUND)"
            [disabled]="selectedRefundableLineItems.length === 0"
          ></zaver-primary-button>
        </div>
      </div>

      <div class="additional-wrapper">
        <p>
          Total order amount:
          {{ paymentRequestTotalAmount | amountPipe : currency }}
        </p>
        <div class="vertical-divider"></div>
        <p>
          Total refundable amount:
          {{ totalRefundableAmount | amountPipe : currency }}
        </p>
      </div>

      <p
        style="
          color: #20252d;
          font-size: 16px;
          line-height: 28px;
          margin-right: 20px;
          font-weight: 700;
          margin-bottom: 16px;
        "
      >
        Refundable
      </p>

      <app-refund-line-item-table
        [allChecked]="allChecked"
        (setAllChecked)="selectAllRefundable($event)"
        [lineItemsVM]="refundableLineItems"
        (changeQuantity)="changeQuantity($event)"
        (setTaxRatePrecent)="setTaxRatePrecent($event)"
        (setEditMode)="setRefundEditMode($event)"
        [currency]="currency"
      ></app-refund-line-item-table>

      <ng-container *ngIf="refundedLineItems?.length > 0">
        <app-simple-line-item-table
          [currency]="currency"
          [headingText]="'Refunded'"
          [simpleLineItemVM]="refundedLineItems"
        ></app-simple-line-item-table>
      </ng-container>
      <p>
        Or,
        <a
          (click)="setView(VIEW.REFUND_FIXED_AMOUNT)"
          style="color: #0a1018; text-decoration: underline; cursor: pointer"
        >
          create a refund for a fixed amount instead
        </a>
      </p>

      <div *ngIf="!!errorMessage" class="errorMessage">{{ errorMessage }}</div>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.REFUND_FIXED_AMOUNT">
      <app-widget-header
        [showError]="showError"
        [text]="'Create refund'"
        [imgSrc]="'assets/icons/Icon-Reset.svg'"
        [setView]="setView"
        [context]="context"
      ></app-widget-header>

      <div class="additional-wrapper">
        <p>
          Total order amount:
          {{ paymentRequestTotalAmount | amountPipe : currency }}
        </p>
        <div class="vertical-divider"></div>
        <p>
          Total refundable amount:
          {{ totalRefundableAmount | amountPipe : currency }}
        </p>
      </div>

      <app-fixed-amount-refund
        (setTaxRatePrecent)="setFixedAmountTaxRatePrecent($event)"
        [totalRefundableAmount]="totalRefundableAmount"
        [fixedAmountRefundForm]="fixedAmountRefundForm"
        [setView]="setView"
        [taxRateOptions]="taxRateOptions"
        [selectedTaxRate]="taxRateOptions[0]"
      ></app-fixed-amount-refund>

      <div *ngIf="!!errorMessage" class="errorMessage">{{ errorMessage }}</div>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.CONFIRM_REFUND">
      <app-confirm-refund
        [inProgress]="inProgress"
        [onCreateRefund]="onCreateRefund"
        [setView]="setView"
        [selectedAmountToRefund]="selectedAmountToRefund"
        [selectedRefundTaxAmount]="selectedRefundTaxAmount"
        [totalOrderAmount]="paymentRequestTotalAmount"
        [uncapturedAmount]="uncapturedAmount"
        [currency]="currency"
      ></app-confirm-refund>
      <div *ngIf="!!errorMessage" class="errorMessage">{{ errorMessage }}</div>
    </ng-container>

    <ng-container *ngSwitchCase="VIEW.CONFIRM_FIXED_AMOUNT_REFUND">
      <app-confirm-fixed-amount-refund
        [inProgress]="inProgress"
        [onCreateRefund]="onCreateRefund"
        [setView]="setView"
        [selectedAmountToRefund]="selectedAmountToRefund"
        [selectedFixedAmountRefundTaxAmount]="
          selectedFixedAmountRefundTaxAmount
        "
        [totalOrderAmount]="paymentRequestTotalAmount"
        [uncapturedAmount]="uncapturedAmount"
        [fixedAmountRefundForm]="fixedAmountRefundForm"
        [currency]="currency"
      ></app-confirm-fixed-amount-refund>
      <div *ngIf="!!errorMessage" class="errorMessage">{{ errorMessage }}</div>
    </ng-container>
  </ng-container>
</div>
