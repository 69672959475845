import { Injectable } from '@angular/core';
import { RestBase } from '../rest-base';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HrefService } from './href.service';
import { PrecheckSummary } from '@core/dto/precheck/PrecheckSummary';
import { PaginatedList } from '@core/dto/PaginatedList';
import { PrecheckRequest } from '@core/dto/precheck/PrecheckRequest';
import { PrecheckLimits } from '@core/dto/precheck/PrecheckLimits';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

export interface PrecheckSearchParameters {
  status?: string[];
  page?: number;
  pageSize?: number;
}

@Injectable()
export class PrecheckService extends RestBase {
  baseHeaders: HttpHeaders;

  private readonly httpReqOptions = { withCredentials: true };

  constructor(private httpClient: HttpClient, hrefService: HrefService,
  ) {
    super(httpClient, hrefService.getApiUrl());
  }

  search(
    searchParams: PrecheckSearchParameters
  ): Observable<PaginatedList<PrecheckSummary>> {
    const url = `${environment.apiUrl}/precheck`;

    let requestParameters = new HttpParams();
    if (searchParams.status) {
      requestParameters = requestParameters.append(
        'status',
        searchParams.status.join(',')
      );
    }
    if (searchParams.page || searchParams.page === 0) {
      requestParameters = requestParameters.append(
        'page',
        searchParams.page.toString()
      );
    }
    if (searchParams.pageSize) {
      requestParameters = requestParameters.append(
        'pageSize',
        searchParams.pageSize.toString()
      );
    }

    return this.httpClient
      .get<PaginatedList<PrecheckSummary>>(url, {
        ...this.httpReqOptions,
        params: requestParameters,
      });
  }

  resendSms(id: string): Promise<boolean> {
    return super.post('/precheck/resendSms/' + id, JSON.stringify({}));
  }

  create(precheckRequest: PrecheckRequest): Promise<PrecheckSummary> {
    return super.post('/precheck', JSON.stringify(precheckRequest));
  }

  getLimits(): Promise<PrecheckLimits> {
    return super.get('/precheck/limits');
  }

  copyLink(precheck: PrecheckSummary) {
    navigator.clipboard.writeText(`${precheck.precheckUrl}`);
  }
}
