import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { PaymentRequestRequest } from '@core/dto/PaymentRequestRequest';
import { BidProgress } from '@core/dto/Progress';
import { RestBase } from '@core/rest-base';
import { HrefService } from './href.service';
import {
  WsService,
  SubTopic,
  PaymentRequestStatusUpdate,
  WebsocketListener,
} from './ws.service';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { PaginatedList } from '@core/dto/PaginatedList';
import { B2bPaymentRequestRequest } from '@core/dto/B2bPaymentRequestRequest';
import { PaymentRefundStatus } from '@core/params';
import { LocaleService } from './locale.service';

export interface PaymentRequestCreationResponse {
  paymentRequestId: string;
}

export interface CompanyInfoResponse {
  companyName: string;
  legalEntityId: string;
  error?: 'COMPANY_NOT_FOUND' | 'UNKNOWN_ERROR' | 'AWAITING_LOOKUP_RESPONSE';
}

export interface VirtualInvoiceReminderRequest {
  paymentRequestId: String;
  phoneNumber: String;
  email: String;
  sendByEmail: Boolean;
  sendByText: Boolean;
  message: String;
  dueDate: Date;
}

export interface VirtualInvoiceReminderResponse
  extends VirtualInvoiceReminderRequest {
  errors: Error[];
  status: 'SENT' | 'FAILED';
}

interface Error {
  errorMessage: string;
  errorCode: 'INVALID_NUMBER' | 'INVALID_EMAIL' | 'UNKNOWN_ERROR';
}

export interface NonMatchingPaymentsResponse {
  nonMatchingPayments: NonMatch[];
  surplusPayments: SurplusPayment[];
  insufficientPayments: InsufficientPayment[];
}

export interface PartialMatch {
  invoiceData: InvoiceData;
  paymentData: PaymentData[];
  paymentRequestId: string;
}

interface InvoiceData {
  virtualInvoiceId: string;
}

export interface PaymentData {
  paymentDate: Date;
  amount: string;
}

export interface NonMatch {
  reference: string;
  paymentDate: Date;
  amount: string;
  paymentRequestId: string;
}

export interface SurplusPayment {
  moneybagEntryId: string;
  reference: string;
  amount: string;
  surplusAmount: string;
  created: Date;
  paymentRequestDate: Date;
  status: SurplusPaymentStatus;
  paymentRequestId: string;
}

export interface InsufficientPayment {
  reference: string;
  paymentDate: Date;
  paymentRequestDate: Date;
  amount: string;
  paymentRequestAmount: string;
  remainingAmount: string;
  paymentRequestId: string;
}

export enum SurplusPaymentStatus {
  UNHANDLED = 'UNHANDLED',
  CS_MESSAGE_SENT = 'CS_MESSAGE_SENT',
}

export interface NonMatchingMatchRequest {
  paymentRequestToMatchWith: PaymentRequestResponse;
  paymentToMatch: NonMatch;
}

export interface PaymentRequestSearchParameters {
  search?: string;
  status?: string[];
  b2bStatus?: string[];
  paymentRefundStatus?: PaymentRefundStatus;
  buyerType?: 'CONSUMER,COMPANY' | 'CONSUMER' | 'COMPANY';
  storeId?: string;
  dateFrom?: Date;
  dateTo?: Date;
  page?: number;
  pageSize?: number;
}

@Injectable()
export class PaymentRequestService
  extends RestBase
  implements WebsocketListener<PaymentRequestStatusUpdate>
{
  private statusListeners: PaymentStatusListener[] = [];
  private readonly httpReqOptions = { withCredentials: true };

  constructor(
    private httpClient: HttpClient,
    hrefService: HrefService,
    wsService: WsService,
    private localeService: LocaleService
  ) {
    super(httpClient, hrefService.getApiUrl());
    wsService.registerTopicListener(SubTopic.PaymentRequestStatus, this);
  }

  paymentRequestId: string;

  public registerStatusListener(listener: PaymentStatusListener): void {
    this.statusListeners.push(listener);
  }

  public unregisterStatusListener(listener: PaymentStatusListener): void {
    this.statusListeners = this.statusListeners.filter((l) => l !== listener);
  }

  handleMessage(message: PaymentRequestStatusUpdate): void {
    this.statusListeners.forEach((l) => {
      l.onPrStatusChange(message.id, message.status);
    });
  }

  public search(
    inputParameters: PaymentRequestSearchParameters
  ): Observable<PaginatedList<PaymentRequestResponse>> {
    if (environment.useFixtures) {
      return this.httpClient.get<PaginatedList<PaymentRequestResponse>>(
        '/assets/fixtures/paymentRequests.json'
      ); // TODO map into something compatible
    }

    const url = `${environment.apiUrl}/paymentRequest`;

    let requestParameters = new HttpParams();
    if (inputParameters.search) {
      requestParameters = requestParameters.append(
        'search',
        inputParameters.search
      );
    }
    if (inputParameters.status?.length) {
      requestParameters = requestParameters.append(
        'status',
        inputParameters.status.join(',')
      );
    }
    if (inputParameters.b2bStatus?.length) {
      requestParameters = requestParameters.append(
        'b2bStatus',
        inputParameters.b2bStatus.join(',')
      );
    }
    if (inputParameters.paymentRefundStatus) {
      requestParameters = requestParameters.append(
        'paymentRefundStatus',
        inputParameters.paymentRefundStatus
      );
    }
    if (inputParameters.buyerType) {
      requestParameters = requestParameters.append(
        'buyerType',
        inputParameters.buyerType
      );
    }
    if (inputParameters.storeId) {
      requestParameters = requestParameters.append(
        'storeFilter',
        inputParameters.storeId
      );
    }
    if (inputParameters.dateFrom) {
      requestParameters = requestParameters.append(
        'dateFrom',
        inputParameters.dateFrom.toISOString()
      );
    }
    if (inputParameters.dateTo) {
      requestParameters = requestParameters.append(
        'dateTo',
        inputParameters.dateTo.toISOString()
      );
    }
    if (inputParameters.page || inputParameters.page === 0) {
      requestParameters = requestParameters.append(
        'page',
        inputParameters.page.toString()
      );
    }
    if (inputParameters.pageSize) {
      requestParameters = requestParameters.append(
        'pageSize',
        inputParameters.pageSize.toString()
      );
    }

    return this.httpClient.get<PaginatedList<PaymentRequestResponse>>(url, {
      ...this.httpReqOptions,
      params: requestParameters,
    });
  }

  public get(): Promise<PaymentRequestResponse[]> {
    if (environment.useFixtures) {
      return this.httpClient
        .get<PaymentRequestResponse[]>('/assets/fixtures/paymentRequests.json')
        .toPromise();
    }

    return super.get('/paymentRequest');
  }

  public getOne(id: string): Promise<PaymentRequestResponse> {
    return super.get('/paymentRequest/' + id);
  }

  public create(
    paymentRequest: PaymentRequestRequest
  ): Promise<PaymentRequestCreationResponse> {
    return super.post('/paymentRequest', JSON.stringify(paymentRequest));
  }

  public createVirtualInvoice(
    paymentRequest: B2bPaymentRequestRequest
  ): Promise<PaymentRequestCreationResponse> {
    return super.post('/paymentRequest/b2b', JSON.stringify(paymentRequest));
  }

  public getBuyerCompanyInfo(buyerNin: string): Promise<CompanyInfoResponse> {
    return super.get('/paymentRequest/b2b/companyInfo/?nin=' + buyerNin);
  }

  public update(
    paymentRequest: PaymentRequestRequest,
    id: string
  ): Promise<BidProgress<string>> {
    return super.put('/paymentRequest/' + id, JSON.stringify(paymentRequest));
  }

  public cancel(id: string): Promise<any> {
    return super.put('/paymentRequest/cancel/' + id, JSON.stringify({}));
  }

  public sign(): Promise<BidProgress<PaymentRequestResponse>> {
    return super.get('/paymentRequest/sign');
  }

  public resend(id: string): Promise<any> {
    return super.post('/paymentRequest/resend/' + id, JSON.stringify({}));
  }

  createVirtualInvoiceReminder(
    virtualInvoiceReminderDto: VirtualInvoiceReminderRequest
  ): Promise<VirtualInvoiceReminderResponse> {
    return super.post(
      '/paymentRequest/b2b/reminder',
      JSON.stringify(virtualInvoiceReminderDto)
    );
  }

  getNonMatchingPayments(): Promise<NonMatchingPaymentsResponse> {
    return super.get('/paymentRequest/b2b/unmatchedPayments');
  }

  getPartialMatches(paymentRequestId: string): Promise<PartialMatch[]> {
    return super.get('/paymentRequest/b2b/partialMatches/' + paymentRequestId);
  }

  matchNonMatchWithPaymentRequest(
    request: NonMatchingMatchRequest
  ): Promise<boolean> {
    return super.post(
      '/paymentRequest/b2b/unmatchedPayments',
      JSON.stringify(request)
    );
  }

  handleSurplusPayment(
    surplusPayment: SurplusPayment
  ): Promise<SurplusPayment> {
    return super.post(
      '/paymentRequest/b2b/handleSurplusPayment',
      JSON.stringify(surplusPayment)
    );
  }

  getVibanFromLoanOfferId(loanOfferId: string): Promise<any> {
    return super.get('/paymentRequest/getVibanFromLoanOfferId/' + loanOfferId);
  }

  copyCheckOutLink(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getOne(this.paymentRequestId)
        .then((response: PaymentRequestResponse) => {
          navigator.clipboard.writeText(response.checkoutLink);
          resolve(response);
        })
        .catch((error: any) => {
          navigator.clipboard.writeText('Link could not be copied...');
          reject(error);
        });
    });
  }
}

export interface PaymentStatusListener {
  onPrStatusChange(id: string, status: string): void;
}
