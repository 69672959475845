import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@core/base.component';
import { PaginatedList } from '@core/dto/PaginatedList';
import { PrecheckLimits } from '@core/dto/precheck/PrecheckLimits';
import { PrecheckStatus } from '@core/dto/precheck/PrecheckStatus';
import { PrecheckSummary } from '@core/dto/precheck/PrecheckSummary';
import { AuthenticationService } from '@core/service/authentication.service';
import { LocaleService } from '@core/service/locale.service';
import { PrecheckService } from '@core/service/precheck.service';
import { ToastMessageService } from '@core/service/toast-message.service';
import { UserAgentService } from '@core/service/user-agent.service';
import { ValueListPipe } from '@shared/pipe/value.pipe';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { QuickAction } from '@zfb/ui/quick-actions/quick-actions.component';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';
import {
  ColumnDefinition,
  FilterOption,
  TableRows,
} from '@zfb/ui/table/table.component';

export enum Modal {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
}

@Component({
  selector: 'app-precheck-de',
  templateUrl: './precheck-de.component.html',
  styleUrls: ['../../page-shared.scss', './precheck-de.component.scss'],
})
export class PrecheckDeComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  constructor(
    public auth: AuthenticationService,
    private localeService: LocaleService,
    private precheckService: PrecheckService,
    private toastService: ToastMessageService,
    private userAgentService: UserAgentService
  ) {
    super(auth);
  }

  quickActions: QuickAction<any>[] = [
    {
      text: $localize`:@@paymentRequests.quickActions.copyLink:Copy link`,
      iconUrl: 'assets/icons/Icon-Link.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (precheckRequest) =>
        precheckRequest.status === PrecheckStatus.PENDING,
      topBorder: false,
      function: (precheckRequest) => {
        this.precheckService.copyLink(precheckRequest);
        // Android 12 has a system toast message when texts are copied to the
        // clipboard. We do not want to make our own toasts in this scenario
        if (this.userAgentService.getAndroidVersion() !== '12') {
          this.toastService.displaySuccess(
            $localize`:@@toast.copyLink.success:The payment link has been copied`
          );
        }
      },
    },
  ];

  merchantPrecheckLimits: PrecheckLimits;

  paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };

  fetchingPrechecks = true;

  displayEmptyState = false;
  currentEmptyState: EmptyState;
  merchantHasPrechecks = false;

  activeModalComponent: Modal;
  modalActive = false;
  modalTitle: string;
  modalColor: string;
  Modal = Modal;
  statePushedToHistory = false;

  noPrechecksEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Otter.png',
    headingText: 'Keine gespeicherten Ergebnisse',
    bodyText:
      'Es liegen keine aktuellen Pre-Checks vor, die hier angezeigt werden könnten.',
    ctaButtonText: 'Betrag testen',
    ctaClickFunction: () => this.create(),
  };
  noPrechecksFilteredEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: 'Hier ist im Moment nichts&nbsp;vorhanden',
  };

  allPrecheckStatuses: PrecheckStatus[] = [
    PrecheckStatus.PENDING,
    PrecheckStatus.GRANTED,
    PrecheckStatus.DENIED,
    PrecheckStatus.CANCELLED,
  ];

  statusFilters: PrecheckStatus[] = this.allPrecheckStatuses;

  selectedPrecheck: PrecheckSummary;
  prechecks: TableRows<PrecheckSummary, PrecheckStatus> = {
    getPrimaryText: (it: PrecheckSummary) =>
      new ValueListPipe().transform(
        it.value,
        this.localeService.getCurrentLocale()
      ),
    getSecondaryText: (it: PrecheckSummary) => it.personName,
    getStatusBarColor: (it) => it.statusColor,
    getBackgroundColor: (it) => it.backgroundColor,
    rows: [],
    hideMobileHeader: false,
    rowClickFunction: (it: PrecheckSummary) => this.open(it),
  };

  columns: ColumnDefinition<PrecheckSummary, PrecheckStatus>[] = [
    {
      text: 'Name',
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (precheck) => precheck.personName,
    },
    {
      text: 'Handynummer',
      columnDataTransformer: (precheck) => precheck.phone,
      cssClasses: 'right-align',
    },
    {
      text: 'Erstellt',
      columnDataTransformer: (precheck) =>
        new DatePipe(this.localeService.getCurrentLocale()).transform(
          precheck.created,
          'd MMM y'
        ),
      cssClasses: 'right-align',
    },
    {
      text: 'Betrag',
      columnDataTransformer: (precheck) =>
        new ValueListPipe().transform(
          precheck.value,
          this.localeService.getCurrentLocale()
        ),
      cssClasses: 'medium2',
    },
    {
      type: 'status',
      filterable: true,
      columnDataTransformer: (precheck) => precheck.statusText,
      columnHeadingStylingTransformer: () => 'width: 210px;',
      columnStylingTransformer: () => 'width: 210px;',
      filterOptions: {
        text: $localize`:@@precheck.de.statusFilter.status:Status`,
        value: 'status',
        activeFilters: [],
        options: [
          {
            headerText: $localize`:@@precheck.de.statusFilter.options.all.headerText:all`,
            displayText: $localize`:@@precheck.de.statusFilter.options.all.displayText:Show all`,
            value: [],
          },
          {
            headerText: $localize`:@@precheck.de.statusFilter.options.pending.headerText:SMS sent`,
            displayText: $localize`:@@precheck.de.statusFilter.options.pending.displayText:SMS sent`,
            value: [PrecheckStatus.PENDING],
          },
          {
            headerText: $localize`:@@precheck.de.statusFilter.options.granted.headerText:approved`,
            displayText: $localize`:@@precheck.de.statusFilter.options.granted.displayText:Approved`,
            value: [PrecheckStatus.GRANTED],
          },
          {
            headerText: $localize`:@@precheck.de.statusFilter.options.denied.headerText:denied`,
            displayText: $localize`:@@precheck.de.statusFilter.options.denied.displayText:Denied`,
            value: [PrecheckStatus.DENIED],
          },
        ],
      },
    },
  ];

  // Event listener for closing full screen modals on backpress
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.deviceIsMobile()) {
      if (this.statePushedToHistory) {
        this.statePushedToHistory = false;
      }
      if (this.modalActive) {
        this.closeModal();
      }
    }
  }

  ngOnInit(): void {
    if (window.location.hash === '#modal') {
      history.back();
    }
    this.fetchPrecheckLimits();
    this.fetchPrechecks();
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    if (this.deviceIsMobile()) {
      if (this.modalActive) {
        this.closeModal();
      }
    }
  }

  async fetchPrecheckLimits() {
    try {
      this.merchantPrecheckLimits = await this.precheckService.getLimits();
    } catch (error) {
      this.handleError(error);
    }
  }

  fetchPrechecks() {
    this.fetchingPrechecks = true;
    this.displayEmptyState = false;
    this.precheckService
      .search({
        status: this.statusFilters,
        page: this.paginationState.page - 1, // page-numbering is zero based on the server
        pageSize: this.paginationState.pageSize,
      })
      .subscribe(
        (res) => {
          this.handleResponse(res);
          this.fetchingPrechecks = false;
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
          this.fetchingPrechecks = false;
        }
      );
  }

  handleResponse(res: PaginatedList<PrecheckSummary>) {
    this.prechecks.rows = res.elements
      .map(PrecheckSummary.setup)
      .map((precheck) => ({
        data: precheck,
        quickActions: this.quickActions.filter((action) =>
          action.displayCondition(precheck)
        ),
      }));

    this.paginationState.numOfItems = this.prechecks.rows.length;
    this.paginationState.page = res.page + 1; // page-numbering is zero based on the server
    this.paginationState.total = res.total;
    if (this.paginationState.numOfItems === 0) {
      this.setEmptyState();
    } else {
      this.merchantHasPrechecks = true;
    }
    this.fetchingPrechecks = false;
  }

  setEmptyState() {
    if (!this.merchantHasPrechecks) {
      this.currentEmptyState = this.noPrechecksEmptyState;
    } else {
      this.currentEmptyState = this.noPrechecksFilteredEmptyState;
    }
    this.displayEmptyState = true;
  }

  onFilterChange(event: {
    type: string;
    filterOption: FilterOption<PrecheckStatus>;
  }) {
    this.statusFilters = event.filterOption.value.length ? event.filterOption.value : this.allPrecheckStatuses;
    const column = this.columns.find((col) => col.type === event.type);
    column.filterOptions.activeFilters = this.statusFilters;
    this.paginationState.page = 1;
    this.fetchPrechecks();
  }

  onPageChange(page: number) {
    this.paginationState.page = page;
    this.fetchPrechecks();
  }

  onPageSizeChange(pageSize: number) {
    this.paginationState.page = 1;
    this.paginationState.pageSize = pageSize;
    this.fetchPrechecks();
  }

  open(precheck: PrecheckSummary) {
    this.selectedPrecheck = precheck;
    this.modalTitle = precheck.statusText;
    this.modalColor = precheck.statusColor;
    this.openModal(Modal.VIEW);
  }

  create() {
    this.modalColor = '#DB305E';
    this.modalTitle = $localize`:@@precheck.de.modal.title:Test amount`;
    this.openModal(Modal.CREATE);
  }

  openModal(modalComponent: Modal) {
    this.activeModalComponent = modalComponent;
    this.modalActive = true;
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(null, null, '/de/precheck#modal');
      this.statePushedToHistory = true;
    }
  }

  closeModal(): void {
    this.modalActive = false;
    this.activeModalComponent = null;
    this.selectedPrecheck = null;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      history.back();
    }
  }

  async resendSms(precheck: PrecheckSummary): Promise<void> {
    try {
      const result: boolean = await this.precheckService.resendSms(precheck.id);
      if (result) {
        this.toastService.displaySuccess(
          $localize`:@@toast.resendSms.success:A new SMS has been sent`
        );
        this.closeModal();
      } else {
        this.toastService.displayError(
          $localize`:@@toast.resendSms.fail:A new SMS could not be sent`
        );
      }
    } catch (error) {
      this.toastService.displayError(
        $localize`:@@toast.resendSms.fail:A new SMS could not be sent`
      );
    }
  }

  handleCreatedPrecheck() {
    this.merchantHasPrechecks = true;
    this.closeModal();
    this.fetchPrechecks();
  }

  deviceIsMobile() {
    return window.innerWidth <= 520;
  }
}
