<div *ngIf="isNotProduction()" class="warning">
  <div class="warning-container">
    <img class="icon-warning" src="assets/icons/Icon-Warning.svg" />
    <p class="text-warning" i18n="@@login.email.warning">
      This is a test environment for Zaver for Business. Please go to the
      production site to log in to your account -
      <a href="https://business.zaver.com/">Click here</a>
    </p>
  </div>
</div>

<div class="card">
  <h1 i18n="@@login.email.title">Welcome!</h1>
  <span class="sub-title" i18n="@@login.email.sub-title"
    >Please enter your business email to log in.</span
  >
  <form [formGroup]="form" id="login-form">
    <div class="input-container">
      <zaver-animated-form-input
        [placeholder]="emailInputPlaceholder"
        [inputMode]="'email'"
        [error]="form.controls.email.invalid && showError"
        [errorMessage]="emailInputErrorMessage"
        formControlName="email"
        [readonly]="processingLogin"
        [size]="'large'"
      ></zaver-animated-form-input>
    </div>

    <div class="switch-container">
      <app-switch
        (change)="rememberMeClicked()"
        i18n-text="@@login.email.rememberme"
        text="Remember me"
        formControlName="rememberMe"
      ></app-switch>
    </div>

    <app-form-error *ngIf="formError" [errorText]="formError"> </app-form-error>

    <div class="button-wrapper">
      <zaver-primary-button
        [buttonType]="'submit'"
        text="Continue"
        i18n-text="@@login.email.button.continue"
        [loading]="processingLogin"
        (click)="submit()"
        [color]="'black'"
      ></zaver-primary-button>
    </div>
  </form>
</div>

<div id="signup-box" i18n="@@login.email.footer">
  <span>Want to start accepting payments with Zaver?</span>
  <a
    [href]="localeService.getLocaleRegistrationLink()"
    class="link"
    target="_blank"
    >Contact us here.</a
  >
</div>
