export enum RepresentativeStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING_USER_APPROVAL = 'PENDING_USER_APPROVAL',
}

export enum PaymentRequestStatus {
  PENDING_CREATION = 'PENDING_CREATION',
  CREATED = 'CREATED',
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  SETTLED = 'SETTLED',
  BUYER_CANCELLED = 'BUYER_CANCELLED',
  CONTROL = 'CONTROL',
  CANCELLED_UPDATED = 'CANCELLED_UPDATED',
  PENDING_SIGN = 'PENDING_SIGN',
  PENDING_BUYER_SIGN = 'PENDING_BUYER_SIGN',
  PENDING_BUYER_REGISTRATION = 'PENDING_BUYER_REGISTRATION',
  PENDING_MERCHANT_SIGN_OFF = 'PENDING_MERCHANT_SIGN_OFF',
  OPEN_REFUND = 'OPEN_REFUND',
  PARTLY_REFUNDED = 'PARTLY_REFUNDED',
  FULLY_REFUNDED = 'FULLY_REFUNDED',
  PARTIALLY_CAPTURED = 'PARTIALLY_CAPTURED',
  VERIFIED_PARTNER_CREATED = 'VERIFIED_PARTNER_CREATED',

  //b2b
  INVOICE_SENT = 'INVOICE_SENT',
  INVOICE_PAST_DUE = 'INVOICE_PAST_DUE',
  INVOICE_PAID = 'INVOICE_PAID',
  INVOICE_CANCELLED = 'INVOICE_CANCELLED',
  INVOICE_CREATED = 'INVOICE_CREATED',
}

export enum CashoutTaskStatus {
  PENDING_PAYEE_APPROVAL = 'PENDING_PAYEE_APPROVAL',
  PENDING_ADMIN_APPROVAL = 'PENDING_ADMIN_APPROVAL',
  PENDING_EXECUTION = 'PENDING_EXECUTION',
  EXECUTED = 'EXECUTED',
  CANCELLED = 'CANCELLED',
  BOUNCED = 'BOUNCED',
  FAILED = 'FAILED',
}

export enum RefundStatus {
  PENDING_MERCHANT_APPROVAL = 'PENDING_MERCHANT_APPROVAL',
  PENDING_EXECUTION = 'PENDING_EXECUTION',
  EXECUTION_INITIATED = 'EXECUTION_INITIATED',
  EXECUTED = 'EXECUTED',
  CANCELLED = 'CANCELLED',
  CANCELLED_BY_SYSTEM = 'CANCELLED_BY_SYSTEM',
  ERROR = 'ERROR',
}

export enum PaymentRefundStatus {
  OPEN_REFUND = 'OPEN_REFUND',
  PARTIAL_REFUND = 'PARTIAL_REFUND',
  FULL_REFUND = 'FULL_REFUND',
}

export enum ReportDisbursementType {
  SALE = 'SALE',
  REFUND = 'REFUND',
}
