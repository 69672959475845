import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { PaymentRequestResponse } from '@core/dto/PaymentRequestResponse';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import {
  InsufficientPayment,
  NonMatch,
  NonMatchingMatchRequest,
  NonMatchingPaymentsResponse,
  PaymentRequestService,
  PaymentStatusListener,
  SurplusPayment,
  SurplusPaymentStatus,
  VirtualInvoiceReminderRequest,
} from '@core/service/payment-request.service';
import { StoreService } from '@core/service/store.service';
import { AppStateService } from '@core/service/app-state.service';
import { CustomizationService } from '@core/service/customization.service';
import { Subject } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PaginatedList } from '@core/dto/PaginatedList';
import { Store } from '@core/dto/Store';
import { ValueListPipe } from '@shared/pipe/value.pipe';
import {
  ColumnDefinition,
  FilterOption,
  TableRow,
  TableRows,
} from '@zfb/ui/table/table.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  PaymentRefundStatus,
  PaymentRequestStatus,
} from '@core/params';
import { SearchPipe } from '@shared/pipe/search.pipe';
import { QuickAction } from '@zfb/ui/quick-actions/quick-actions.component';
import { ToastMessageService } from '@core/service/toast-message.service';
import { UserAgentService } from '@core/service/user-agent.service';
import { AccessControlService } from '@core/service/access-control.service';
import { Permission } from '@core/dto/user-details';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { ColorService } from '@core/service/color.service';
import { LocaleService } from '@core/service/locale.service';
import { PageTab } from '@zfb/ui/page-tabs/page-tabs.component';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';
import { DropdownListItemv2 } from '../../../../../web-component-library/projects/component-library/src/lib/dropdownv2/dropdownv2.component';
import { Router } from '@angular/router';

enum ModalComponent {
  CREATE_PAYMENT = 'CREATE_PAYMENT',
  VIEW_PAYMENT = 'VIEW_PAYMENT',
  CREATE_REMINDER = 'CREATE_REMINDER',
  MATCH_PAYMENTS = 'MATCH_PAYMENTS',
  CREATE_REFUND = 'CREATE_REFUND',
}

enum SmallModalComponent {
  PAYMENT_CREATED = 'PAYMENT_CREATED',
  B2B_PAYMENT_CREATED = 'B2B_PAYMENT_CREATED',
  CANCEL_PAYMENT = 'CANCEL_PAYMENT',
  CONFIRM_SURPLUS = 'CONFIRM_SURPLUS',
  CONFIRM_REMINDER = 'CONFIRM_REMINDER',
  CONFIRM_MATCH = 'CONFIRM_MATCH',
  PAYMENT_LINK_CREATED_COPY_LINK = 'PAYMENT_LINK_CREATED_COPY_LINK',
}

@Component({
  selector: 'app-payment-requests',
  templateUrl: './payment-requests.component.html',
  styleUrls: ['../page-shared.scss', './payment-requests.component.css'],
})
export class PaymentRequestsComponent
  extends BaseComponent
  implements OnInit, OnDestroy, PaymentStatusListener
{
  constructor(
    private paymentRequestService: PaymentRequestService,
    private storeService: StoreService,
    protected auth: AuthenticationService,
    private appStateService: AppStateService,
    private customizationService: CustomizationService,
    private searchPipe: SearchPipe,
    private toastService: ToastMessageService,
    private userAgentService: UserAgentService,
    private access: AccessControlService,
    public colorService: ColorService,
    private localeService: LocaleService,
    private router: Router
  ) {
    super(auth);

    this.searchQueryChanged
      .pipe(
        map((q) => q.trim()),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((query) => {
        this.paginationState.page = 1;
        this.nonMatchedPaymentsPaginationState.page = 1;
        this.surplusPaymentsPaginationState.page = 1;
        this.searchPaymentRequests(query);
      });
  }
  statePushedToHistory = false;

  currentReminderRequest: VirtualInvoiceReminderRequest;

  invokedFromList = false; // This is used to know if an action was invoked through a quickaction in the table

  quickActions: QuickAction<PaymentRequestResponse>[] = [
    {
      text: $localize`:@@paymentRequests.quickActions.resendSms:Send SMS`,
      iconUrl: 'assets/icons/Icon-Send-Black.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (paymentRequest: PaymentRequestResponse) =>
        !!paymentRequest.phone &&
        paymentRequest.status === PaymentRequestStatus.OPEN &&
        paymentRequest.buyerType === 'CONSUMER',
      topBorder: false,
      function: (paymentRequest) => this.resendRequestSms(paymentRequest),
    },
    {
      text: 'Skicka påminnelse',
      iconUrl: 'assets/icons/Icon-Send-Black.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (paymentRequest) =>
        paymentRequest.buyerType === 'COMPANY' &&
        !!paymentRequest.b2bPaymentData &&
        paymentRequest.b2bPaymentData.status ===
        PaymentRequestStatus.INVOICE_PAST_DUE,
      topBorder: false,
      function: (paymentRequest) => {
        this.openCreateReminderModal(paymentRequest, true);
      },
    },
    {
      text: $localize`:@@paymentRequests.quickActions.copyLink:Copy link`,
      iconUrl: 'assets/icons/Icon-Link.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (paymentRequest) =>
        paymentRequest.status === PaymentRequestStatus.OPEN &&
        paymentRequest.buyerType === 'CONSUMER' &&
        !!paymentRequest.checkoutLink,
      topBorder: false,
      function: (paymentRequest) => {
        navigator.clipboard.writeText(paymentRequest.checkoutLink);
        // Android 12 has a system toast message when texts are copied to the
        // clipboard. We do not want to make our own toasts in this scenario
        if (this.userAgentService.getAndroidVersion() !== '12') {
          this.toastService.displaySuccess(
            $localize`:@@toast.copyLink.success:The payment link has been copied`
          );
        }
      },
    },
    {
      text: $localize`:@@paymentRequests.quickActions.cancelPaymentRequest:Cancel the request`,
      iconUrl: 'assets/icons/Icon-Delete-Cerise.svg',
      textColor: this.colorService.getCeriseColorCode(),
      disabled: false,
      displayCondition: (paymentRequest) =>
        this.access.userMay(Permission.CANCEL_PAYMENT_REQUEST) &&
        paymentRequest.status === PaymentRequestStatus.OPEN,
      topBorder: true,
      function: (paymentRequest) => {
        this.openPaymentRequestCancelModal(paymentRequest, true);
      },
      buttonId: 'click_cancel_paymentrequest_table_quick_action', // Used for Tag manager
    },
    {
      text: $localize`:@@paymentRequests.quickActions.createRefund:Create refund`,
      iconUrl: 'assets/icons/Icon-Reset-Cerise.svg',
      textColor: this.colorService.getCeriseColorCode(),
      disabled: false,
      displayCondition: (paymentRequest) => {
        return (
          !this.appStateService.merchantHasAutomaticRefundEnabled() &&
          this.appStateService.merchantHasRefundAccess() &&
          this.access.userMay(Permission.CREATE_REFUND) &&
          (paymentRequest.status === PaymentRequestStatus.SETTLED ||
            paymentRequest.status === PaymentRequestStatus.OPEN_REFUND ||
            paymentRequest.status === PaymentRequestStatus.PARTLY_REFUNDED)
        );
      },
      topBorder: true,
      function: (paymentRequest) => {
        if (paymentRequest.lineItems?.length > 0) {
          this.router.navigate([`/widget`], {
            queryParams: { paymentrequest: paymentRequest.id },
          });
        } else {
          this.openCreateRefundModal(paymentRequest, true);
        }
      },
    },
    {
      text: 'Manage order',
      iconUrl: 'assets/icons/Icon-Capture-Checkmark.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (paymentRequest) => {
        return (
          paymentRequest.lineItems?.length > 0 &&
          (paymentRequest.status ===
            PaymentRequestStatus.PENDING_MERCHANT_SIGN_OFF ||
            paymentRequest.status === PaymentRequestStatus.PARTIALLY_CAPTURED ||
            paymentRequest.status === PaymentRequestStatus.SETTLED ||
            paymentRequest.status === PaymentRequestStatus.PARTLY_REFUNDED)
        );
      },
      topBorder: true,
      function: (paymentRequest) => {
        this.router.navigate([`/widget`], {
          queryParams: { paymentrequest: paymentRequest.id },
        });
      },
    },
  ];

  paymentRequests: TableRows<PaymentRequestResponse, PaymentRequestStatus> = {
    getPrimaryText: (it: PaymentRequestResponse) =>
      new ValueListPipe().transform(
        it.value,
        this.localeService.getCurrentLocale()
      ),
    getSecondaryText: (it: PaymentRequestResponse) => it.title,
    getStatusBarColor: (it) => it.statusColor,
    getBackgroundColor: (it) => {
      return it.backgroundColor;
    },
    rows: [],
    hideMobileHeader: false,
    rowClickFunction: (it: PaymentRequestResponse) =>
      this.openViewPaymentRequestModal(it),
  };
  merchantHasPaymentRequests = false;
  merchantHasConsumerPaymentRequests = false;
  merchantHasCompanyPaymentRequests = false;
  preventClosingmodalByAccident = false;

  nonMatchedPayments: NonMatch[] = [];
  searchedListOfNonMatchedPayments: NonMatch[];
  nonMatchedPaymentsPaginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };

  currentPageOfNonMatchedPayments: TableRows<NonMatch, PaymentRequestStatus> = {
    getPrimaryText: (it: NonMatch) =>
      new CurrencyPipe('sv', 'SEK').transform(
        it.amount,
        'SEK',
        'code',
        '1.2-2',
        'sv'
      ),
    getSecondaryText: (it: NonMatch) => it.reference,
    getMobileTextColumnStyle: () => '',
    getStatusBarColor: () => this.colorService.getCeriseColorCode(),
    rows: [],
    hideMobileHeader: true,
  };

  selectedNonMatchedPayment: NonMatch;
  nonMatchingMatchRequest: NonMatchingMatchRequest;
  nonMatchedPaymentsColumns: ColumnDefinition<
    NonMatch,
    PaymentRequestStatus
  >[] = [
    {
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (nonMatch: NonMatch) => nonMatch.reference,
      text: $localize`:@@paymentRequests.table.columnText.ocrMessage:OCR/Message`,
    },
    {
      columnDataTransformer: (nonMatch: NonMatch) =>
        new DatePipe('sv').transform(nonMatch.paymentDate, 'mediumDate'),
      text: $localize`:@@paymentRequests.table.columnText.paidAmount:Payment`,
      cssClasses: 'right-align',
    },
    {
      columnDataTransformer: (nonMatch: NonMatch) =>
        new CurrencyPipe('sv', 'SEK').transform(
          nonMatch.amount,
          'SEK',
          'code',
          '1.2-2',
          'sv'
        ),
      text: $localize`:@@paymentRequests.table.columnText.amount:Amount`,
      cssClasses: 'medium2 right-align',
    },
    {
      type: 'handleAction',
      actionClicked: (nonMatch) => this.openMatchPaymentsModal(nonMatch),
    },
  ];

  surplusNavTabText = this.deviceIsMobile()
    ? $localize`:@@paymentRequests.b2bTabs.overPayments.mobile:Ov.payment`
    : $localize`:@@paymentRequests.b2bTabs.overPayments.desktop:Overpayment`;
  surplusPayments: SurplusPayment[] = [];
  searchedListOfSurplusPayments: SurplusPayment[];
  surplusPaymentsPaginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };

  currentPageOfSurplusPayments: TableRows<
    SurplusPayment,
    PaymentRequestStatus
  > = {
    getPrimaryText: (it: SurplusPayment) =>
      new CurrencyPipe('sv', 'SEK').transform(
        it.surplusAmount,
        'SEK',
        'code',
        '1.2-2',
        'sv'
      ),
    getSecondaryText: (it: SurplusPayment) => it.reference,
    getStatusBarColor: (it: SurplusPayment) =>
      it.status === SurplusPaymentStatus.UNHANDLED ? '#4466EE' : '#00A880',
    rows: [],

    hideMobileHeader: true,
    rowClickFunction: (it: SurplusPayment) => this.openSurplusPaymentModal(it),
  };

  selectedSurplusPayment: SurplusPayment;
  surplusPaymentColumns: ColumnDefinition<
    SurplusPayment,
    PaymentRequestStatus
  >[] = [
    {
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (surplusPayment: SurplusPayment) =>
        surplusPayment.reference,
      text: $localize`:@@paymentRequests.table.columnText.ocrMessage:OCR/Message`,
    },
    {
      columnDataTransformer: (surplusPayment: SurplusPayment) =>
        new DatePipe('sv').transform(
          surplusPayment.paymentRequestDate,
          'mediumDate'
        ),
      text: $localize`:@@paymentRequests.table.columnText.created:Created`,
      cssClasses: 'right-align',
    },
    {
      columnDataTransformer: (surplusPayment: SurplusPayment) =>
        new CurrencyPipe('sv', 'SEK').transform(
          surplusPayment.amount,
          'SEK',
          'code',
          '1.2-2',
          'sv'
        ),
      text: $localize`:@@paymentRequests.table.columnText.amount:Amount`,
      cssClasses: 'medium2 right-align',
    },
    {
      columnDataTransformer: (surplusPayment: SurplusPayment) =>
        new CurrencyPipe('sv', 'SEK').transform(
          surplusPayment.surplusAmount,
          'SEK',
          'code',
          '1.2-2',
          'sv'
        ),
      text: $localize`:@@paymentRequests.table.columnText.overPayment:Overpayment`,
      cssClasses: 'medium2 right-align cerise-text',
    },
    {
      type: 'sendSupport',
      value: (surplusPayment) => surplusPayment.status,
      cssClasses: 'right-align',
      actionClicked: (surplusPayment) =>
        this.openSurplusPaymentConfirmationModal(surplusPayment),
    },
  ];

  insufficientNavTabText = this.deviceIsMobile()
    ? $localize`:@@paymentRequests.b2bTabs.underPayments.mobile:Un.payment`
    : $localize`:@@paymentRequests.b2bTabs.underPayments.desktop:Underpayment`;
  insufficientPayments: InsufficientPayment[] = [];
  searchedListOfInsufficientPayments: InsufficientPayment[];
  insufficientPaymentsPaginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };

  currentPageOfInsufficientPayments: TableRows<NonMatch, PaymentRequestStatus> =
    {
      getPrimaryText: (it: InsufficientPayment) => {
        const paidAmount = new CurrencyPipe('sv', 'SEK').transform(
          it.amount,
          'SEK',
          'code',
          '1.2-2',
          'sv'
        );
        const requestedAmount = new CurrencyPipe('sv', 'SEK').transform(
          it.paymentRequestAmount,
          'SEK',
          'code',
          '1.2-2',
          'sv'
        );
        return (
          '<span class="text-color-primary-green">' +
          paidAmount +
          '</span>' +
          '&nbsp;/&nbsp;' +
          requestedAmount
        );
      },
      getSecondaryText: (it: InsufficientPayment) => it.reference,
      getMobileTextColumnStyle: () => 'width: calc(100vw - 72px);',
      getStatusBarColor: () => '#4466EE',
      rows: [],
      hideMobileHeader: true,
      rowClickFunction: (it: NonMatch) => this.openInsufficientPaymentModal(it),
    };

  selectedInsufficientPayment: InsufficientPayment;
  insufficientPaymentColumns: ColumnDefinition<
    InsufficientPayment,
    PaymentRequestStatus
  >[] = [
    {
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (insufficientPayment) =>
        insufficientPayment.reference,
      text: $localize`:@@paymentRequests.table.columnText.ocrMessage:OCR/Message`,
    },
    {
      columnDataTransformer: (insufficientPayment) =>
        new DatePipe('sv').transform(
          insufficientPayment.paymentRequestDate,
          'mediumDate'
        ),
      text: $localize`:@@paymentRequests.table.columnText.created:Created`,
      cssClasses: 'right-align',
    },
    {
      columnDataTransformer: (insufficientPayment) =>
        new CurrencyPipe('sv', 'SEK').transform(
          insufficientPayment.paymentRequestAmount,
          'SEK',
          'code',
          '1.2-2',
          'sv'
        ),
      text: $localize`:@@paymentRequests.table.columnText.amount:Amount`,
      cssClasses: 'medium2 right-align',
    },
    {
      columnDataTransformer: (insufficientPayment) =>
        new CurrencyPipe('sv', 'SEK').transform(
          insufficientPayment.amount,
          'SEK',
          'code',
          '1.2-2',
          'sv'
        ),
      text: $localize`:@@paymentRequests.table.columnText.paidAmount:Payment`,
      cssClasses: 'medium2 right-align',
      columnStylingTransformer: () => 'color: #009773;',
    },
    {
      columnDataTransformer: (insufficientPayment) =>
        new CurrencyPipe('sv', 'SEK').transform(
          insufficientPayment.remainingAmount,
          'SEK',
          'code',
          '1.2-2',
          'sv'
        ),
      text: $localize`:@@paymentRequests.table.columnText.remainingAmount:Remaining`,
      cssClasses: 'medium2 right-align cerise-text',
      columnStylingTransformer: () => 'width: 150px;',
      columnHeadingStylingTransformer: () => 'width: 150px;',
    },
  ];

  tabs: PageTab<'CONSUMER,COMPANY' | 'CONSUMER' | 'COMPANY'>[] = [
    {
      value: 'CONSUMER,COMPANY',
      label: $localize`:@@paymentRequests.showAll:Show all`,
    },
    {
      value: 'CONSUMER',
      label: $localize`:@@paymentRequests.consumer:Consumer`,
    },
    {
      value: 'COMPANY',
      label: $localize`:@@paymentRequests.company:Company`,
    },
  ];

  fetchingResponse: boolean;
  fetchingNonMatchingPayments = false;
  selectedPaymentRequest: PaymentRequestResponse = null;
  cancelPaymentRequestPromptIsOpen = false;
  prSubmitted = false;
  cancellationInProgress = false;
  submittingSurplusMessage = false;

  public paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };

  public searchQuery = '';
  private searchQueryChanged: Subject<string> = new Subject<string>();

  public statusFilters: PaymentRequestStatus[] = [];
  public b2bStatusFilters: PaymentRequestStatus[] = [];

  public storesFilter = '';
  storesFilterOptions: DropdownListItemv2[] = null;
  canViewStoresFilter = false;

  activeModalComponent: ModalComponent;
  activeSmallModalComponent: SmallModalComponent;
  modalTitle: string;
  modalColor: string;
  modalContent: string;

  merchantHasB2B: boolean;
  buyerTypeFilter: 'CONSUMER,COMPANY' | 'CONSUMER' | 'COMPANY';
  // Used when buyerTypeFilter is CONSUMER,COMPANY to only get CONSUMER/COMPANY specific PRs
  tempBuyerTypeFilter: 'CONSUMER' | 'COMPANY' = null;
  companyPageFilter: '' | 'NON_MATCHED' | 'SURPLUS' | 'INSUFFICIENT' = '';

  paymentRefundStatus: PaymentRefundStatus;

  columns: ColumnDefinition<PaymentRequestResponse, PaymentRequestStatus>[] =
    [];

  consumerColumns: ColumnDefinition<
    PaymentRequestResponse,
    PaymentRequestStatus
  >[] = [
    {
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (paymentRequest) => paymentRequest.title,
      text: this.getAliasForTitleInForm(),
    },
    {
      columnDataTransformer: (paymentRequest) =>
        new DatePipe(this.localeService.getCurrentLocale()).transform(
          paymentRequest.created,
          'd MMM y'
        ),
      text: $localize`:@@paymentRequests.consumerColumns.created:Created`,
      cssClasses: 'right-align',
    },
    {
      columnDataTransformer: (paymentRequest) =>
        new ValueListPipe().transform(
          paymentRequest.value,
          this.localeService.getCurrentLocale()
        ),
      text: $localize`:@@paymentRequests.consumerColumns.amount:Amount`,
      cssClasses: 'medium2 right-align',
    },
    {
      filterable: true,
      type: 'status',
      text: 'Status',
      columnDataTransformer: (pr) => pr.statusText,
      columnHeadingStylingTransformer: () => 'width: 210px;',
      columnStylingTransformer: () => 'width: 210px;',
      filterOptions: {
        text: $localize`:@@paymentRequests.consumerColumns.statusFilter:Status`,
        value: 'status',
        activeFilters: [],
        options: [
          {
            headerText: $localize`:@@paymentRequests.consumerColumns.statusFilter.options.all.headerText:all`,
            displayText: $localize`:@@paymentRequests.consumerColumns.statusFilter.options.all.displayText:Show all`,
            value: [],
          },
          {
            headerText: $localize`:@@paymentRequests.consumerColumns.statusFilter.options.OPEN.headerText:pending response`,
            displayText: $localize`:@@paymentRequests.consumerColumns.statusFilter.options.OPEN.displayText:Pending response`,
            value: [PaymentRequestStatus.OPEN],
          },
          {
            headerText: $localize`:@@paymentRequests.consumerColumns.statusFilter.options.SETTLED.headerText:approved`,
            displayText: $localize`:@@paymentRequests.consumerColumns.statusFilter.options.SETTLED.displayText:Approved`,
            value: [PaymentRequestStatus.SETTLED],
          },
          {
            headerText: $localize`:@@paymentRequests.consumerColumns.statusFilter.options.CANCELLED.headerText:cancelled`,
            displayText: $localize`:@@paymentRequests.consumerColumns.statusFilter.options.CANCELLED.displayText:Cancelled`,
            value: [PaymentRequestStatus.CANCELLED],
          },
        ],
      },
    },
  ];

  companyColumns: ColumnDefinition<
    PaymentRequestResponse,
    PaymentRequestStatus
  >[] = [
    {
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (paymentRequest) => paymentRequest.title,
      text: this.getAliasForTitleInForm(),
    },
    {
      columnDataTransformer: (paymentRequest) =>
        new DatePipe('sv').transform(paymentRequest.created, 'mediumDate'),
      text: $localize`:@@paymentRequests.table.columnText.created:Created`,
      cssClasses: 'right-align',
    },
    {
      columnDataTransformer: (paymentRequest) =>
        new DatePipe('sv').transform(
          paymentRequest.b2bPaymentData.dueDate,
          'mediumDate'
        ),
      text: $localize`:@@paymentRequests.table.columnText.finalDate:Due date`,
      columnStylingTransformer: (paymentRequest) =>
        paymentRequest.b2bPaymentData.status ===
        PaymentRequestStatus.INVOICE_PAST_DUE
          ? 'color:  #DB305E;'
          : '',
      cssClasses: 'right-align',
    },
    {
      columnDataTransformer: (paymentRequest) =>
        new ValueListPipe().transform(
          paymentRequest.value,
          this.localeService.getCurrentLocale()
        ),
      text: $localize`:@@paymentRequests.table.columnText.amount:Amount`,
      cssClasses: 'medium2 right-align',
    },
    {
      filterable: true,
      type: 'status',
      text: $localize`:@@paymentRequests.table.columnText.status:Status`,
      columnDataTransformer: (pr) => pr.statusText,
      columnHeadingStylingTransformer: () => 'width: 210px;',
      columnStylingTransformer: () => 'width: 210px;',
      filterOptions: {
        text: $localize`:@@paymentRequests.table.columnText.status:Status`,
        value: 'status',
        activeFilters: [],
        options: [
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.all:all`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.all:Show all`,
            value: [],
          },
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.confirmed:approved`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.confirmed:Approved`,
            value: [PaymentRequestStatus.INVOICE_PAID],
          },
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.cancelled:cancelled`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.cancelled:Cancelled`,
            value: [PaymentRequestStatus.INVOICE_CANCELLED],
          },
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.unpaid:unpaid`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.unpaid:Unpaid`,
            value: [PaymentRequestStatus.INVOICE_CREATED],
          },
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.pastDue:past due`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.pastDue:Past due`,
            value: [PaymentRequestStatus.INVOICE_PAST_DUE],
          },
        ],
      },
    },
  ];

  consumerCompanyCombinedColumns: ColumnDefinition<
    PaymentRequestResponse,
    PaymentRequestStatus
  >[] = [
    {
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (paymentRequest) => paymentRequest.title,
      text: this.getAliasForTitleInForm(),
    },
    {
      columnDataTransformer: (paymentRequest) => {
        switch (paymentRequest.buyerType) {
          case 'COMPANY':
            return $localize`:@@paymentRequests.company:Company`;
          case 'CONSUMER':
            return $localize`:@@paymentRequests.consumer:Consumer`;
        }
      },
      text: 'Typ',
      cssClasses: 'right-align',
    },
    {
      columnDataTransformer: (paymentRequest) =>
        new DatePipe('sv').transform(paymentRequest.created, 'mediumDate'),
      text: $localize`:@@paymentRequests.table.columnText.created:Created`,
      cssClasses: 'right-align',
    },
    {
      columnDataTransformer: (paymentRequest) =>
        new ValueListPipe().transform(
          paymentRequest.value,
          this.localeService.getCurrentLocale()
        ),
      text: $localize`:@@paymentRequests.table.columnText.amount:Amount`,
      cssClasses: 'medium2 right-align',
    },
    {
      filterable: true,
      type: 'status',
      text: $localize`:@@paymentRequests.table.columnText.status:Status`,
      columnDataTransformer: (pr) => pr.statusText,
      columnHeadingStylingTransformer: () => 'width: 210px;',
      columnStylingTransformer: () => 'width: 210px;',
      filterOptions: {
        text: $localize`:@@paymentRequests.table.columnText.status:Status`,
        value: 'status',
        activeFilters: [],
        options: [
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.all:all`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.all:Show all`,
            value: [],
          },
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.open:pending reply`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.open:Pending reply`,
            value: [PaymentRequestStatus.OPEN],
          },
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.confirmed:approved`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.confirmed:Approved`,
            value: [PaymentRequestStatus.SETTLED, PaymentRequestStatus.INVOICE_PAID],
          },
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.cancelled:cancelled`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.cancelled:Cancelled`,
            value: [PaymentRequestStatus.CANCELLED, PaymentRequestStatus.INVOICE_CANCELLED],
          },
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.unpaid:unpaid`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.unpaid:Unpaid`,
            value: [PaymentRequestStatus.INVOICE_CREATED],
          },
          {
            headerText: $localize`:@@paymentRequests.statusFilter.headerText.pastDue:past due`,
            displayText: $localize`:@@paymentRequests.statusFilter.displayText.pastDue:Past due`,
            value: [PaymentRequestStatus.INVOICE_PAST_DUE],
          },
        ],
      },
    },
  ];

  displayEmptyState = false;
  currentEmptyState: EmptyState;
  noPaymentRequestsSearchedEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Monocle.png',
    headingText: $localize`:@@paymentRequests.emptyState.noPaymentRequestsSearchedEmptyState.headingText:We couldn't find a match for&nbsp;search`,
    bodyText: $localize`:@@paymentRequests.emptyState.noPaymentRequestsSearchedEmptyState.bodyText:Give it another attempt. Try&nbsp;again.`,
  };
  noPaymentRequestsFilteredEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: $localize`:@@paymentRequests.emptyState.noPaymentRequestsFilteredEmptyState.headingText:Looking a little empty right&nbsp;now`,
  };

  noPaymentRequestsAtAllEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Smile.png',
    headingText: $localize`:@@paymentRequests.emptyState.noPaymentRequestsAtAllEmptyState.headingText:Create your first&nbsp;payment request`,
    bodyText: $localize`:@@paymentRequests.emptyState.noPaymentRequestsAtAllEmptyState.bodyText:Looks a little empty now, but as soon as the first payment request has been created it can be found&nbsp;here.`,
    ctaButtonText: $localize`:@@paymentRequests.emptyState.noPaymentRequestsAtAllEmptyState.ctaButtonText:New payment`,
    ctaClickFunction: () => this.openCreatePaymentRequestModal(),
  };

  noB2cPaymentRequestsEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Waving-Hand.png',
    headingText: $localize`:@@paymentRequests.emptyState.noB2cPaymentRequestsEmptyState.headingText:There is nothing here&nbsp;yet`,
    bodyText: $localize`:@@paymentRequests.emptyState.noB2cPaymentRequestsEmptyState.bodyText:Looks a little empty now, but as soon as the first B2C payment has been created it can be found&nbsp;here.`,
    ctaButtonText: $localize`:@@paymentRequests.emptyState.noB2cPaymentRequestsEmptyState.ctaButtonText:New payment`,
    ctaClickFunction: () => this.openCreatePaymentRequestModal(),
  };

  noB2bPaymentRequestsEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Waving-Hand.png',
    headingText: $localize`:@@paymentRequests.emptyState.noB2bPaymentRequestsEmptyState.headingText:There is nothing here&nbsp;yet`,
    bodyText: $localize`:@@paymentRequests.emptyState.noB2bPaymentRequestsEmptyState.bodyText:Looks a little empty now, but as soon as the first B2C payment has been created it can be found&nbsp;here.`,
    ctaButtonText: $localize`:@@paymentRequests.emptyState.noB2bPaymentRequestsEmptyState.ctaButtonText:New payment`,
    ctaClickFunction: () => this.openCreatePaymentRequestModal(),
  };

  noSurplusPaymentsEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Male-Detective.png',
    headingText: $localize`:@@paymentRequests.emptyState.noSurplusPaymentsEmptyState.headingText:No excess payments`,
    bodyText: $localize`:@@paymentRequests.emptyState.noSurplusPaymentsEmptyState.bodyText:Looks like everything is in order&nbsp;here.`,
  };

  noInsufficientPaymentsEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Female-Detective.png',
    headingText: $localize`:@@paymentRequests.emptyState.noInsufficientPaymentsEmptyState.headingText:No underpayments`,
    bodyText: $localize`:@@paymentRequests.emptyState.noInsufficientPaymentsEmptyState.bodyText:Looks like everything is in order&nbsp;here.`,
  };

  noNonMatchedPaymentsEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Girl-Laptop.png',
    headingText: $localize`:@@paymentRequests.emptyState.noNonMatchedPaymentsEmptyState.headingText:No unmatched&nbsp;payments`,
    bodyText: $localize`:@@paymentRequests.emptyState.noNonMatchedPaymentsEmptyState.bodyText:Looks like everything is in order&nbsp;here.`,
  };

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.deviceIsMobile()) {
      if (this.statePushedToHistory) {
        this.statePushedToHistory = false;
      }
      if (this.activeModalComponent) {
        this.deactivateModal();
      } else if (this.activeSmallModalComponent) {
        if (
          this.activeSmallModalComponent === SmallModalComponent.CANCEL_PAYMENT
        ) {
          this.returnToMainModal(SmallModalComponent.CANCEL_PAYMENT);
        } else {
          if (
            this.activeSmallModalComponent !==
            SmallModalComponent.PAYMENT_LINK_CREATED_COPY_LINK
          ) {
            this.deactivateSmallModal();
          }
        }
      }
    }
  }

  ngOnInit() {
    if (window.location.hash === '#modal') {
      history.back();
    }
    this.paymentRequestService.registerStatusListener(this);

    this.merchantHasB2B = this.appStateService.merchantHasB2bPayments();
    if (this.merchantHasB2B) {
      this.buyerTypeFilter = 'CONSUMER,COMPANY';
    } else {
      this.buyerTypeFilter = 'CONSUMER';
    }

    if (this.appStateService.merchantHasRefundAccess()) {
      this.addRefundStatusFiltersToColumns();
    }

    this.addCaptureStatusFiltersToColumns();

    this.setup();

    if (this.repMayViewStores()) {
      this.storeService
        .get()
        .then((res) => {
          if (res.length > 0) {
            this.setupStores(res);
            this.canViewStoresFilter = true;
          }
        })
        .catch((error) => this.handleError(error));
    }
    window.onbeforeunload = () => this.ngOnDestroy();
  }

  setupColumns() {
    if (
      !this.merchantHasB2B ||
      (this.merchantHasB2B && this.buyerTypeFilter === 'CONSUMER')
    ) {
      this.columns = this.consumerColumns;
    } else if (this.merchantHasB2B && this.buyerTypeFilter === 'COMPANY') {
      this.columns = this.companyColumns;
    } else {
      this.columns = this.consumerCompanyCombinedColumns;
    }
  }

  addCaptureStatusFiltersToColumns() {
    this.consumerColumns[3].filterOptions.options.push({
      headerText: $localize`:@@paymentRequestStatus.PENDING_MERCHANT_SIGN_OFF:Ready for payment`,
      displayText: $localize`:@@paymentRequestStatus.PENDING_MERCHANT_SIGN_OFF:Ready for payment`,
      value: [PaymentRequestStatus.PENDING_MERCHANT_SIGN_OFF],
    });
  }

  addRefundStatusFiltersToColumns() {
    this.consumerColumns[3].filterOptions.options.push(
      {
        headerText: $localize`:@@paymentRequests.consumerColumns.refundFilterOptions.OPEN_REFUND.headerText:refund in progress`,
        displayText: $localize`:@@paymentRequests.consumerColumns.refundFilterOptions.OPEN_REFUND.displayText:Refund in progress`,
        value: [PaymentRequestStatus.OPEN_REFUND],
      },
      {
        headerText: $localize`:@@paymentRequests.consumerColumns.refundFilterOptions.PARTIALLY_REFUNDED.headerText:partial refund`,
        displayText: $localize`:@@paymentRequests.consumerColumns.refundFilterOptions.PARTIALLY_REFUNDED.displayText:Partial refund`,
        value: [PaymentRequestStatus.PARTLY_REFUNDED],
      },
      {
        headerText: $localize`:@@paymentRequests.consumerColumns.refundFilterOptions.FULLY_REFUNDED.headerText:refunded`,
        displayText: $localize`:@@paymentRequests.consumerColumns.refundFilterOptions.FULLY_REFUNDED.displayText:Refunded`,
        value: [PaymentRequestStatus.FULLY_REFUNDED],
      },
      {
        headerText: $localize`:@@paymentRequestStatus.PARTIALLY_CAPTURED:Partially approved`,
        displayText: $localize`:@@paymentRequestStatus.PARTIALLY_CAPTURED:Partially approved`,
        value: [PaymentRequestStatus.PARTIALLY_CAPTURED],
      }
    );

    if (this.merchantHasB2B) {
      this.companyColumns[4].filterOptions.options.push(
        {
          headerText: $localize`:@@paymentRequests.companyColumns.refundFilterOptions.OPEN_REFUND.headerText:refund in progress`,
          displayText: $localize`:@@paymentRequests.companyColumns.refundFilterOptions.OPEN_REFUND.displayText:Refund in progress`,
          value: [PaymentRequestStatus.OPEN_REFUND],
        },
        {
          headerText: $localize`:@@paymentRequests.companyColumns.refundFilterOptions.PARTIALLY_REFUNDED.headerText:partial refund`,
          displayText: $localize`:@@paymentRequests.companyColumns.refundFilterOptions.PARTIALLY_REFUNDED.displayText:Partial refund`,
          value: [PaymentRequestStatus.PARTLY_REFUNDED],
        },
        {
          headerText: $localize`:@@paymentRequests.companyColumns.refundFilterOptions.FULLY_REFUNDED.headerText:refunded`,
          displayText: $localize`:@@paymentRequests.companyColumns.refundFilterOptions.FULLY_REFUNDED.displayText:Refunded`,
          value: [PaymentRequestStatus.FULLY_REFUNDED],
        }
      );

      this.consumerCompanyCombinedColumns[4].filterOptions.options.push(
        {
          headerText: $localize`:@@paymentRequests.consumerCompanyCombinedColumns.refundFilterOptions.OPEN_REFUND.headerText:refund in progress`,
          displayText: $localize`:@@paymentRequests.consumerCompanyCombinedColumns.refundFilterOptions.OPEN_REFUND.displayText:Refund in progress`,
          value: [PaymentRequestStatus.OPEN_REFUND],
        },
        {
          headerText: $localize`:@@paymentRequests.consumerCompanyCombinedColumns.refundFilterOptions.PARTIALLY_REFUNDED.headerText:partial refund`,
          displayText: $localize`:@@paymentRequests.consumerCompanyCombinedColumns.refundFilterOptions.PARTIALLY_REFUNDED.displayText:Partial refund`,
          value: [PaymentRequestStatus.PARTLY_REFUNDED],
        },
        {
          headerText: $localize`:@@paymentRequests.consumerCompanyCombinedColumns.refundFilterOptions.FULLY_REFUNDED.headerText:refunded`,
          displayText: $localize`:@@paymentRequests.consumerCompanyCombinedColumns.refundFilterOptions.FULLY_REFUNDED.displayText:Refunded`,
          value: [PaymentRequestStatus.FULLY_REFUNDED],
        }
      );
    }
  }

  repMayViewStores(): boolean {
    return this.appStateService.repHasStoreAccess();
  }

  setupStores(stores: Store[]) {
    this.storesFilterOptions = [];
    this.storesFilterOptions.push({
      label: $localize`:@@paymentRequests.storeOptions.all:Show all ${this.getStoreAlias(
        true
      )}`,
      value: '',
    });
    for (const store of stores) {
      this.storesFilterOptions.push({
        label: store.name,
        value: store.id,
      });
    }
  }

  ngOnDestroy(): void {
    this.paymentRequestService.unregisterStatusListener(this);
    if (this.deviceIsMobile()) {
      if (this.activeModalComponent) {
        this.deactivateModal();
      } else if (this.activeSmallModalComponent) {
        this.deactivateSmallModal();
      }
    }
  }
  activePageChange(buyerType: 'CONSUMER,COMPANY' | 'CONSUMER' | 'COMPANY') {
    if (this.buyerTypeFilter !== buyerType) {
      this.buyerTypeFilter = buyerType;
      this.companyPageFilter = '';
      this.paginationState = { page: 1, pageSize: 10, total: 0, numOfItems: 0 };
      this.statusFilters = [];
      this.b2bStatusFilters = [];
      this.columns[this.columns.length - 1].filterOptions.activeFilters = [];
      this.setup();
    }
  }

  activeCompanyPageChange(
    companyFilter: '' | 'NON_MATCHED' | 'SURPLUS' | 'INSUFFICIENT'
  ) {
    if (this.companyPageFilter !== companyFilter) {
      this.companyPageFilter = companyFilter;
      if (
        companyFilter === 'NON_MATCHED' ||
        companyFilter === 'SURPLUS' ||
        companyFilter === 'INSUFFICIENT'
      ) {
        this.fetchNonMatchingPayments();
      } else {
        this.setup();
      }
    }
  }

  onPrStatusChange(id: string, status: string): void {
    for (let row of this.paymentRequests.rows) {
      if (row.data.id === id) {
        row.data.status = status;
        PaymentRequestResponse.setup(row.data);

        row.quickActions = this.setupQuickActionsForPaymentRequest(
          row.data
        ).quickActions;

        if (
          this.activeModalComponent === ModalComponent.VIEW_PAYMENT &&
          this.selectedPaymentRequest.id === id
        ) {
          this.updateModalTextAndColor({
            text: row.data.statusText,
            color: row.data.statusColor,
          });
        }

        return;
      }
    }

    if (status != PaymentRequestStatus.VERIFIED_PARTNER_CREATED) {
      // found no payment request with that id, it might have been created by another user => fetch it!
      this.paymentRequestService.getOne(id).then((newPr) => {
        PaymentRequestResponse.setup(newPr);
        // this might occur with a delay so lets check that it has not been added in the meantime
        for (const row of this.paymentRequests.rows) {
          if (row.data.id === newPr.id) {
            return;
          }
        }

        this.paymentRequests.rows.unshift(
          this.setupQuickActionsForPaymentRequest(newPr)
        );
        this.merchantHasPaymentRequests = true;
      });
    }
  }

  private async setup() {
    super.clearError();
    this.setupColumns();

    this.refreshPaymentRequests();
    if (this.merchantHasB2B) {
      this.fetchNonMatchingPayments();
    }
  }

  searchUpdatedQuery(query: string) {
    this.searchQueryChanged.next(query);
  }

  searchPaymentRequests(query: string) {
    this.searchQuery = query.trim();
    if (
      this.buyerTypeFilter === 'COMPANY' &&
      (this.companyPageFilter === 'NON_MATCHED' ||
        this.companyPageFilter === 'SURPLUS' ||
        this.companyPageFilter === 'INSUFFICIENT')
    ) {
      this.setupListOfNonMatchingPayments();
    } else {
      this.refreshPaymentRequests();
    }
  }

  onStoresFilterChange(event: string) {
    this.storesFilter = event;
    this.refreshPaymentRequests();
  }

  onActiveStatusChange(event: {
    type: string;
    filterOption: FilterOption<PaymentRequestStatus>;
  }) {
    this.statusFilters = [];
    this.paymentRefundStatus = null;
    this.b2bStatusFilters = [];
    this.tempBuyerTypeFilter = null;
    this.paymentRefundStatus = null;
    this.paginationState.page = 1;
    const column = this.columns.find((col) => col.type === event.type);
    if (column) {
      column.filterOptions.activeFilters = event.filterOption.value;
    }
    if (event.type === 'status') {
      let paymentRequestStatusFilter: PaymentRequestStatus[] = [];

      for (let index = 0; index < event.filterOption.value.length; index++) {
        const filterOption = event.filterOption.value[index];
        if (filterOption === PaymentRequestStatus.OPEN_REFUND ||
          filterOption === PaymentRequestStatus.PARTLY_REFUNDED ||
          filterOption === PaymentRequestStatus.FULLY_REFUNDED ||
          filterOption === PaymentRequestStatus.INVOICE_PAID ||
          filterOption === PaymentRequestStatus.SETTLED) {
            paymentRequestStatusFilter.push(PaymentRequestStatus.SETTLED);
        } else if (filterOption === PaymentRequestStatus.CANCELLED ||
          filterOption === PaymentRequestStatus.INVOICE_CANCELLED
        ) {
          paymentRequestStatusFilter.push(PaymentRequestStatus.CANCELLED, PaymentRequestStatus.BUYER_CANCELLED, PaymentRequestStatus.CANCELLED_UPDATED);
        } else if (filterOption !== PaymentRequestStatus.INVOICE_CREATED &&
          filterOption !== PaymentRequestStatus.INVOICE_PAST_DUE
        ) {
          paymentRequestStatusFilter.push(filterOption);
        }

        switch (filterOption) {
          case PaymentRequestStatus.OPEN: // B2C/B2C+B2B
          case PaymentRequestStatus.PENDING_MERCHANT_SIGN_OFF: //B2C
          case PaymentRequestStatus.PARTIALLY_CAPTURED: //B2C
            this.tempBuyerTypeFilter = 'CONSUMER';
            break;
          case PaymentRequestStatus.INVOICE_PAID: // B2B
          case PaymentRequestStatus.SETTLED: // B2C+B2B
            this.b2bStatusFilters.push(PaymentRequestStatus.INVOICE_PAID);
            break;
          case PaymentRequestStatus.CANCELLED: // B2C
          case PaymentRequestStatus.INVOICE_CANCELLED: // B2B
            this.b2bStatusFilters.push(PaymentRequestStatus.INVOICE_CANCELLED);
            break;
          case PaymentRequestStatus.INVOICE_CREATED: // B2B/B2C+B2B
          case PaymentRequestStatus.INVOICE_PAST_DUE: // B2B/B2C+B2B
            this.b2bStatusFilters.push(filterOption);
            if (this.buyerTypeFilter !== 'COMPANY') {
              this.tempBuyerTypeFilter = 'COMPANY';
            }
            break;
          case PaymentRequestStatus.OPEN_REFUND:
            this.paymentRefundStatus = PaymentRefundStatus.OPEN_REFUND;
            break;
          case PaymentRequestStatus.PARTLY_REFUNDED:
            this.paymentRefundStatus = PaymentRefundStatus.PARTIAL_REFUND;
            break;
          case PaymentRequestStatus.FULLY_REFUNDED:
            this.paymentRefundStatus = PaymentRefundStatus.FULL_REFUND;
            break;
          default:
            this.columns[this.columns.length - 1].filterOptions.activeFilters =
              [];
            break;
        }
      }

      this.statusFilters = paymentRequestStatusFilter;
      this.refreshPaymentRequests();
    }
  }

  getStoreAlias(plural: boolean): string {
    return this.customizationService.getStoreAlias(plural);
  }

  handleModalHeaderChange(headerBuyerType: string): void {
    this.updateModalTextAndColor({
      text:
        $localize`:@@paymentRequests.modal.createPaymentRequest.header:New payment` +
        headerBuyerType,
      color: this.colorService.getCeriseColorCode(),
    });
  }

  onPageChange(type: '' | 'SURPLUS' | 'NON_MATCHED' | 'INSUFFICIENT', $event) {
    switch (type) {
      case '':
        this.paginationState.page = $event;
        this.refreshPaymentRequests();
        break;
      case 'SURPLUS':
        this.surplusPaymentsPaginationState.page = $event;
        this.fetchNonMatchingPayments();
        break;
      case 'NON_MATCHED':
        this.nonMatchedPaymentsPaginationState.page = $event;
        this.fetchNonMatchingPayments();
        break;
      case 'INSUFFICIENT':
        this.insufficientPaymentsPaginationState.page = $event;
        this.fetchNonMatchingPayments();
        break;
      default:
        break;
    }
  }

  onPageSizeChange(
    type: '' | 'SURPLUS' | 'NON_MATCHED' | 'INSUFFICIENT',
    $event
  ) {
    switch (type) {
      case '':
        this.paginationState.page = 1;
        this.paginationState.pageSize = $event;
        this.refreshPaymentRequests();
        break;
      case 'SURPLUS':
        this.surplusPaymentsPaginationState.page = 1;
        this.surplusPaymentsPaginationState.pageSize = $event;
        this.fetchNonMatchingPayments();
        break;
      case 'NON_MATCHED':
        this.nonMatchedPaymentsPaginationState.page = 1;
        this.nonMatchedPaymentsPaginationState.pageSize = $event;
        this.fetchNonMatchingPayments();
        break;
      case 'INSUFFICIENT':
        this.insufficientPaymentsPaginationState.page = 1;
        this.insufficientPaymentsPaginationState.pageSize = $event;
        this.fetchNonMatchingPayments();
        break;
      default:
        break;
    }
  }

  async refreshPaymentRequests() {
    this.fetchingResponse = true;
    if (
      this.buyerTypeFilter !== 'COMPANY' ||
      (this.buyerTypeFilter === 'COMPANY' && this.companyPageFilter === '')
    ) {
      this.displayEmptyState = false;
    }
    return this.paymentRequestService
      .search({
        search: this.searchQuery,
        status: this.statusFilters,
        b2bStatus: this.b2bStatusFilters,
        paymentRefundStatus: this.paymentRefundStatus,
        buyerType: this.tempBuyerTypeFilter
          ? this.tempBuyerTypeFilter
          : this.buyerTypeFilter,
        storeId: this.storesFilter,
        page: this.paginationState.page - 1, // page-numbering is zero based on the server
        pageSize: this.paginationState.pageSize,
      })
      .subscribe(
        (res) => {
          this.handleResponse(res);
          this.fetchingResponse = false;
        },
        (err) => {
          this.handleError(err);
          this.fetchingResponse = false;
        }
      );
  }

  handleResponse(res: PaginatedList<PaymentRequestResponse>) {
    this.tempBuyerTypeFilter = null;

    res.elements.forEach((pr) => PaymentRequestResponse.setup(pr));
    this.paymentRequests.rows = res.elements.map((element) => {
      return this.setupQuickActionsForPaymentRequest(element);
    });

    this.paginationState.page = res.page + 1; // page-numbering is zero based on the server
    this.paginationState.total = res.total;
    this.paginationState.numOfItems = this.paymentRequests.rows.length;
    if (res.total > 0) {
      this.merchantHasPaymentRequests = true;
      if (this.merchantHasB2B && this.buyerTypeFilter === 'CONSUMER') {
        this.merchantHasConsumerPaymentRequests = true;
      } else if (this.merchantHasB2B && this.buyerTypeFilter === 'COMPANY') {
        this.merchantHasCompanyPaymentRequests = true;
      }
    } else {
      this.setEmptyState();
    }
  }

  async fetchNonMatchingPayments() {
    this.fetchingNonMatchingPayments = true;
    if (this.buyerTypeFilter === 'COMPANY' && this.companyPageFilter !== '') {
      this.displayEmptyState = false;
    }
    return this.paymentRequestService
      .getNonMatchingPayments()
      .then((response: NonMatchingPaymentsResponse) => {
        this.nonMatchedPayments = response.nonMatchingPayments;
        this.surplusPayments = response.surplusPayments;
        this.insufficientPayments = response.insufficientPayments;
        if (
          this.buyerTypeFilter === 'COMPANY' &&
          this.companyPageFilter !== ''
        ) {
          switch (this.companyPageFilter) {
            case 'NON_MATCHED':
              this.setupListOfNonMatchingPayments();
              break;
            case 'SURPLUS':
              this.setupListOfSurplusPayments();
              break;
            case 'INSUFFICIENT':
              this.setupListOfInsufficientPayments();
              break;
            default:
              break;
          }
        }
        this.fetchingNonMatchingPayments = false;
      })
      .catch((err) => {
        this.handleError(err);
        this.fetchingNonMatchingPayments = false;
      });
  }

  setupListOfNonMatchingPayments() {
    this.searchedListOfNonMatchedPayments = this.searchPipe.transform(
      this.nonMatchedPayments,
      'reference,paymentDate,amount',
      this.searchQuery
    );

    if (
      this.nonMatchedPayments.length === 0 ||
      this.searchedListOfNonMatchedPayments.length === 0
    ) {
      this.setEmptyState();
      return;
    }

    this.currentPageOfNonMatchedPayments.rows =
      this.searchedListOfNonMatchedPayments
        .slice(
          (this.nonMatchedPaymentsPaginationState.page - 1) *
            this.nonMatchedPaymentsPaginationState.pageSize,
          this.nonMatchedPaymentsPaginationState.page *
            this.nonMatchedPaymentsPaginationState.pageSize
        )
        .map((nonMatchedPayment) => ({
          data: nonMatchedPayment,
          quickActions: null,
        }));
    this.nonMatchedPaymentsPaginationState.numOfItems =
      this.currentPageOfNonMatchedPayments.rows.length;
    this.nonMatchedPaymentsPaginationState.total =
      this.nonMatchedPayments.length;
  }

  setupListOfSurplusPayments() {
    this.searchedListOfSurplusPayments = this.searchPipe.transform(
      this.surplusPayments,
      'reference,amount,surplusAmount,created',
      this.searchQuery
    );

    if (
      this.surplusPayments.length === 0 ||
      this.searchedListOfSurplusPayments.length === 0
    ) {
      this.setEmptyState();
      return;
    }

    this.currentPageOfSurplusPayments.rows = this.searchedListOfSurplusPayments
      .slice(
        (this.surplusPaymentsPaginationState.page - 1) *
          this.surplusPaymentsPaginationState.pageSize,
        this.surplusPaymentsPaginationState.page *
          this.surplusPaymentsPaginationState.pageSize
      )
      .map((surplusPayment) => ({ data: surplusPayment, quickActions: null }));
    this.surplusPaymentsPaginationState.numOfItems =
      this.currentPageOfSurplusPayments.rows.length;
    this.surplusPaymentsPaginationState.total = this.surplusPayments.length;
  }

  setupListOfInsufficientPayments() {
    this.searchedListOfInsufficientPayments = this.searchPipe.transform(
      this.insufficientPayments,
      'reference,amount,paymentDate',
      this.searchQuery
    );

    if (
      this.insufficientPayments.length === 0 ||
      this.searchedListOfInsufficientPayments.length === 0
    ) {
      this.setEmptyState();
      return;
    }

    this.currentPageOfInsufficientPayments.rows =
      this.searchedListOfInsufficientPayments
        .slice(
          (this.insufficientPaymentsPaginationState.page - 1) *
            this.insufficientPaymentsPaginationState.pageSize,
          this.insufficientPaymentsPaginationState.page *
            this.insufficientPaymentsPaginationState.pageSize
        )
        .map((insufficientPayment) => ({
          data: insufficientPayment,
          quickActions: null,
        }));
    this.insufficientPaymentsPaginationState.numOfItems =
      this.currentPageOfInsufficientPayments.rows.length;
    this.insufficientPaymentsPaginationState.total =
      this.insufficientPayments.length;
  }

  getAliasForTitleInForm(): string {
    const guiCustomizations = this.appStateService.getGuiCustomizations();
    if (guiCustomizations !== null && guiCustomizations.prFormTitleAlias) {
      return guiCustomizations.prFormTitleAlias;
    }

    return $localize`:@@form.header.title:Title`;
  }

  deviceIsMobile() {
    return window.innerWidth <= 520;
  }

  /*
  Modal methods
  */

  activateModal(component: ModalComponent) {
    this.preventClosingmodalByAccident =
      component === ModalComponent.CREATE_PAYMENT;
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(
        null,
        null,
        `/${this.localeService.getCurrentLocaleBaseCode()}/paymentRequests#modal`
      );
      this.statePushedToHistory = true;
    }
    this.activeModalComponent = component;
  }

  deactivateModal() {
    this.activeModalComponent = null;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      history.back();
    }
  }

  activateSmallModal(component: SmallModalComponent) {
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(
        null,
        null,
        `/${this.localeService.getCurrentLocaleBaseCode()}/paymentRequests#modal`
      );
      this.statePushedToHistory = true;
    }
    this.activeSmallModalComponent = component;
  }

  deactivateSmallModal() {
    this.activeSmallModalComponent = null;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      history.back();
    }
  }

  updateModalTextAndColor(event: { text: string; color: string }) {
    if (event.color) {
      this.modalColor = event.color;
    }
    if (event.text) {
      this.modalTitle = event.text;
    }
  }

  handleModalEvent(
    component: ModalComponent | SmallModalComponent,
    event?: string
  ) {
    switch (component) {
      case SmallModalComponent.CONFIRM_SURPLUS:
        this.selectedSurplusPayment = null;
        this.deactivateSmallModal();
        this.fetchNonMatchingPayments();
        break;
      case SmallModalComponent.CONFIRM_MATCH:
        this.nonMatchingMatchRequest = null;
        this.deactivateSmallModal();
        this.fetchNonMatchingPayments();
        break;
      case SmallModalComponent.CANCEL_PAYMENT:
        this.deactivateSmallModal();
        this.setup();
        break;
      case ModalComponent.CREATE_REFUND:
        this.deactivateModal();
        this.setup();
        break;
      case ModalComponent.CREATE_PAYMENT:
        this.merchantHasPaymentRequests = true;
        this.prSubmitted = true;
        this.deactivateModal();
        this.setup();
        if (event === 'b2c_link') {
          this.openLinkModal(true);
        }
        if (event === 'b2b') {
          this.openPaymentRequestConfirmationModal(true);
        } else if (event === 'b2c') {
          this.openPaymentRequestConfirmationModal(false);
        }
        break;
      default:
        break;
    }
  }

  /*
  Open modal methods
  */
  openCreatePaymentRequestModal() {
    this.selectedPaymentRequest = null;

    if (this.merchantHasB2B) {
      this.updateModalTextAndColor({
        text: $localize`:@@paymentRequests.modal.selectCustomerPaymentRequest.header:Select customer category`,
        color: this.colorService.getCeriseColorCode(),
      });
    } else {
      this.updateModalTextAndColor({
        text: $localize`:@@paymentRequests.modal.createPaymentRequest.header:New payment`,
        color: this.colorService.getCeriseColorCode(),
      });
    }

    this.activateModal(ModalComponent.CREATE_PAYMENT);
  }

  openViewPaymentRequestModal(pr: PaymentRequestResponse) {
    this.selectedPaymentRequest = pr;
    this.updateModalTextAndColor({
      text: this.selectedPaymentRequest.statusText,
      color: this.selectedPaymentRequest.statusColor,
    });
    this.activateModal(ModalComponent.VIEW_PAYMENT);
  }

  openPaymentRequestConfirmationModal(paymentIsB2B: boolean) {
    this.updateModalTextAndColor({
      text: $localize`:@@paymentRequests.modal.confirmation.header:Request sent`,
      color: '#00A880',
    });
    if (paymentIsB2B) {
      this.activateSmallModal(SmallModalComponent.B2B_PAYMENT_CREATED);
    } else {
      this.activateSmallModal(SmallModalComponent.PAYMENT_CREATED);
    }
  }

  openPaymentRequestCancelModal(
    pr: PaymentRequestResponse,
    invokedFromList: boolean
  ) {
    this.invokedFromList = invokedFromList;
    this.selectedPaymentRequest = pr;
    this.deactivateModal();
    this.updateModalTextAndColor({
      text: $localize`:@@paymentRequests.modal.cancelPaymentRequest.header:Cancel the request`,
      color: '#4466EE',
    });
    this.activateSmallModal(SmallModalComponent.CANCEL_PAYMENT);
  }

  openCreateReminderModal(
    pr: PaymentRequestResponse,
    invokedFromList: boolean
  ) {
    this.invokedFromList = invokedFromList;
    this.selectedPaymentRequest = pr;
    this.updateModalTextAndColor({
      text: $localize`:@@paymentRequests.modal.reminderPaymentRequest.header:Send reminder`,
      color: '#B31760',
    });
    this.activateModal(ModalComponent.CREATE_REMINDER);
  }

  openMatchPaymentsModal(nonMatchedPayment: NonMatch) {
    this.selectedNonMatchedPayment = nonMatchedPayment;
    this.updateModalTextAndColor({
      text: $localize`:@@paymentRequests.modal.unmatchedPaymentRequest.header:Unmatched payment`,
      color: '#4466EE',
    });
    this.activateModal(ModalComponent.MATCH_PAYMENTS);
  }

  openLinkModal(paymentLinkSelected: boolean) {
    this.updateModalTextAndColor({
      text: $localize`:@@paymentRequests.modal.openLinkPaymentRequest.header:Link created`,
      color: '#00A880',
    });
    if (paymentLinkSelected) {
      this.activateSmallModal(
        SmallModalComponent.PAYMENT_LINK_CREATED_COPY_LINK
      );
    } else {
      this.activateSmallModal(SmallModalComponent.PAYMENT_CREATED);
    }
  }

  openInsufficientPaymentModal(nonMatchedPayment: NonMatch) {
    this.paymentRequestService
      .getOne(nonMatchedPayment.paymentRequestId)
      .then((paymentRequest) => {
        PaymentRequestResponse.setup(paymentRequest);
        this.openViewPaymentRequestModal(paymentRequest);
      })
      .catch((err) => this.handleError(err));
  }

  openSurplusPaymentModal(surplusPayment: SurplusPayment) {
    this.paymentRequestService
      .getOne(surplusPayment.paymentRequestId)
      .then((paymentRequest) => {
        PaymentRequestResponse.setup(paymentRequest);
        this.openViewPaymentRequestModal(paymentRequest);
      })
      .catch((err) => this.handleError(err));
  }

  openCreateRefundModal(pr: PaymentRequestResponse, invokedFromList: boolean) {
    this.invokedFromList = invokedFromList;
    this.selectedPaymentRequest = pr;
    this.updateModalTextAndColor({
      text: $localize`:@@paymentRequests.modal.createRefund.header:Create refund`,
      color: this.colorService.getCeriseColorCode(),
    });
    this.activateModal(ModalComponent.CREATE_REFUND);
  }

  openPaymentMatchConfirmationModal(request: NonMatchingMatchRequest) {
    this.nonMatchingMatchRequest = request;
    this.deactivateModal();
    this.updateModalTextAndColor({
      text: $localize`:@@paymentRequests.modal.matchPaymentRequest.header:Match payment`,
      color: '#4466EE',
    });
    this.activateSmallModal(SmallModalComponent.CONFIRM_MATCH);
  }

  openReminderConfirmationModal(
    reminderRequest: VirtualInvoiceReminderRequest
  ) {
    this.currentReminderRequest = reminderRequest;
    this.deactivateModal();
    this.updateModalTextAndColor({
      text: $localize`:@@paymentRequests.modal.reminderPaymentRequest.header:Send reminder`,
      color: '#CA1A6C',
    });
    this.activateSmallModal(SmallModalComponent.CONFIRM_REMINDER);
  }

  openSurplusPaymentConfirmationModal(payment: SurplusPayment) {
    this.selectedSurplusPayment = payment;
    this.updateModalTextAndColor({
      text: $localize`:@@paymentRequests.modal.contactSupportPaymentRequest.header:Contact support`,
      color: '#00A880',
    });
    this.activateSmallModal(SmallModalComponent.CONFIRM_SURPLUS);
  }

  /*
   Return to main modal component
  */
  returnToMainModal(
    component: SmallModalComponent | ModalComponent,
    pr?: PaymentRequestResponse
  ) {
    this.deactivateSmallModal();
    this.deactivateModal();
    if (this.invokedFromList) {
      this.invokedFromList = false;
      return;
    }
    switch (component) {
      case SmallModalComponent.CONFIRM_REMINDER:
        this.openCreateReminderModal(pr, false);
        break;
      case ModalComponent.CREATE_REMINDER:
      case SmallModalComponent.CANCEL_PAYMENT:
        this.openViewPaymentRequestModal(this.selectedPaymentRequest);
        break;
      case SmallModalComponent.CONFIRM_MATCH:
        this.openMatchPaymentsModal(
          this.nonMatchingMatchRequest.paymentToMatch
        );
        break;
      default:
        break;
    }
  }

  // Given a PaymentRequestResponse, returns a TableRow object with
  // the PaymentRequestResponse and all the quickActions applicable
  // for the PaymentRequestResponse
  setupQuickActionsForPaymentRequest(
    pr: PaymentRequestResponse
  ): TableRow<PaymentRequestResponse> {
    return {
      data: pr,
      quickActions: this.quickActions.filter((action) =>
        action.displayCondition(pr)
      ),
    };
  }

  resendRequestSms(pr: PaymentRequestResponse) {
    this.paymentRequestService
      .resend(pr.id)
      .then((success: boolean) => {
        if (success) {
          this.toastService.displaySuccess(
            $localize`:@@toast.resendSms.success:A new SMS has been sent`
          );
        } else {
          this.toastService.displayError(
            $localize`:@@toast.resendSms.fail:A new SMS could not be sent`
          );
        }
      })
      .catch(() => {
        this.toastService.displayError(
          $localize`:@@toast.resendSms.fail:A new SMS could not be sent`
        );
      });
  }

  mayCreatePaymentRequests(): boolean {
    return this.access.userMay(Permission.CREATE_PAYMENT_REQUEST);
  }

  setEmptyState() {
    if (this.merchantHasPaymentRequests) {
      if (this.merchantHasB2B) {
        if (
          this.buyerTypeFilter === 'CONSUMER' &&
          !this.merchantHasConsumerPaymentRequests
        ) {
          // The merchant have payment requests, but no b2c payment requests
          this.currentEmptyState = this.noB2cPaymentRequestsEmptyState;
        } else if (
          this.buyerTypeFilter === 'COMPANY' &&
          this.companyPageFilter === '' &&
          !this.merchantHasCompanyPaymentRequests
        ) {
          // The merchant have payment requests, but no b2b payment requests
          this.currentEmptyState = this.noB2bPaymentRequestsEmptyState;
        } else if (
          this.buyerTypeFilter === 'COMPANY' &&
          this.companyPageFilter === 'SURPLUS'
        ) {
          if (this.surplusPayments.length === 0) {
            // No surplus payments exist at all
            this.currentEmptyState = this.noSurplusPaymentsEmptyState;
          } else if (
            this.searchedListOfSurplusPayments.length === 0 &&
            this.searchQuery !== ''
          ) {
            // Surplus payments exist, but none that matches the search query
            this.currentEmptyState = this.noPaymentRequestsSearchedEmptyState;
          }
        } else if (
          this.buyerTypeFilter === 'COMPANY' &&
          this.companyPageFilter === 'INSUFFICIENT'
        ) {
          if (this.insufficientPayments.length === 0) {
            // No insufficient payments exist at all
            this.currentEmptyState = this.noInsufficientPaymentsEmptyState;
          } else if (
            this.searchedListOfInsufficientPayments.length === 0 &&
            this.searchQuery !== ''
          ) {
            // Insufficient payments exist, but none that matches the search query
            this.currentEmptyState = this.noPaymentRequestsSearchedEmptyState;
          }
        } else if (
          this.buyerTypeFilter === 'COMPANY' &&
          this.companyPageFilter === 'NON_MATCHED'
        ) {
          if (this.nonMatchedPayments.length === 0) {
            // No non-matched payments exist at all
            this.currentEmptyState = this.noNonMatchedPaymentsEmptyState;
          } else if (
            this.searchedListOfNonMatchedPayments.length === 0 &&
            this.searchQuery !== ''
          ) {
            // Non-matched payments exist, but none that matches the search query
            this.currentEmptyState = this.noPaymentRequestsSearchedEmptyState;
          }
        } else if (
          this.searchQuery !== '' &&
          !this.b2bStatusFilters.length &&
          !this.statusFilters.length &&
          this.storesFilter === ''
        ) {
          this.currentEmptyState = this.noPaymentRequestsSearchedEmptyState;
        } else if (
          this.b2bStatusFilters.length ||
          this.statusFilters.length ||
          this.storesFilter !== ''
        ) {
          this.currentEmptyState = this.noPaymentRequestsFilteredEmptyState;
        }
      } else {
        if (
          this.searchQuery !== '' &&
          !this.statusFilters.length &&
          this.storesFilter === ''
        ) {
          this.currentEmptyState = this.noPaymentRequestsSearchedEmptyState;
        } else if (this.statusFilters.length || this.storesFilter !== '') {
          this.currentEmptyState = this.noPaymentRequestsFilteredEmptyState;
        }
      }
    } else {
      this.currentEmptyState = this.noPaymentRequestsAtAllEmptyState;
    }

    this.displayEmptyState = true;
  }

  copyCheckOutLink() {
    this.paymentRequestService
      .copyCheckOutLink()
      .then(() => {
        this.toastService.displaySuccess(
          $localize`:@@toast.linkcopied.success:The link was created and copied to the clipboard`
        );
        this.deactivateSmallModal();
      })
      .catch((error: any) => {
        this.toastService.displayError(
          $localize`:@@toast.linkcopied.fail:Failed to copy payment link`
        );
      });
  }
}
