<div class="card">
  <h4>
    <span i18n="@@emailNotFound.title"
      >The email address provided does not appear to be affiliated with any
      company.</span
    >
  </h4>
  <p>
    <span i18n="@@emailNotFound.firstParagraph"
      >The email adress provided (<span class="email">{{ email }}</span
      >) doesn't seem to be connected to any company. If your company already
      uses Zaver, you should contact the company administrator.</span
    >
  </p>

  <ng-container>
    <p i18n="@@emailNotFound.lastParagraph">
      If your company does not use Zaver but would like to become a customer of
      ours, please feel free to contact us. Click on the link below and fill out
      the form. We will get back to you as soon as possible.
    </p>
    <p>
      <a
        i18n="@@emailNotFound.lastParagraph.start"
        [href]="localeService.getLocaleRegistrationLink()"
        target="_blank"
        class="link"
        >start Zaver</a
      >
    </p>
  </ng-container>

  <div class="button-wrapper">
    <zaver-primary-button
      text="Back"
      i18n-text="@@emailNotFound.button"
      (click)="goBack()"
      [color]="'cerise'"
    ></zaver-primary-button>
  </div>
</div>

<div id="signup-box" i18n="@@login.email.footer">
  <span>Want to start accepting payments with Zaver?</span>
  <a
    [href]="localeService.getLocaleRegistrationLink()"
    class="link"
    target="_blank"
    >Contact us here.</a
  >
</div>
