import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CashoutTaskDto } from '@core/dto/cashout/incoming/CashoutTaskDto';
import {
  CashoutTaskListener,
  CashoutTaskService,
} from '@core/service/cashout-task.service';
import { AccessControlService } from '@core/service/access-control.service';
import {
  CashoutBalanceListener,
  CashoutBalanceService,
} from '@core/service/cashout-balance.service';
import { CashoutBalanceResponseDto } from '@core/dto/cashout/incoming/CashoutBalanceResponseDto';
import { AppStateService } from '@core/service/app-state.service';
import { CashoutTaskStatus } from '@core/params';
import { CashoutTaskListDto } from '@core/dto/cashout/incoming/CashoutTaskListDto';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {
  CashoutTaskRepresentation,
  CashoutTaskRepresentations,
} from 'assets/language/CashoutTaskRepresentation';
import { CashoutTaskUpdatedDto } from '@core/dto/cashout/incoming/CashoutTaskUpdatedDto';
import {
  ColumnDefinition,
  FilterOption,
  TableRows,
  TableRow,
} from '../../ui/table/table.component';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '@core/base.component';
import { AuthenticationService } from '@core/service/authentication.service';
import { Permission } from '@core/dto/user-details';
import { PaginationState } from '@zfb/ui/table-pagination/table-pagination.component';
import { ValueListPipe } from '@shared/pipe/value.pipe';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { EmptyState } from '@zfb/ui/empty-state/empty-state.component';
import { UserAgentService } from '@core/service/user-agent.service';
import { QuickAction } from '@zfb/ui/quick-actions/quick-actions.component';
import { ToastMessageService } from '@core/service/toast-message.service';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-cashout',
  templateUrl: './cashout.component.html',
  styleUrls: ['./cashout.component.css', '../page-shared.scss'],
})
export class CashoutComponent
  extends BaseComponent
  implements OnInit, OnDestroy, CashoutBalanceListener, CashoutTaskListener
{
  constructor(
    public localeService: LocaleService,
    private taskService: CashoutTaskService,
    private appStateService: AppStateService,
    private accessControlService: AccessControlService,
    private cashoutBalanceService: CashoutBalanceService,
    private userAgentService: UserAgentService,
    private toastService: ToastMessageService,
    protected auth: AuthenticationService,
  ) {
    super(auth);

    this.searchQueryChanged
      .pipe(
        map((q) => q.trim()),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((query) => {
        this.paginationState.page = 1;
        this.searchTasks(query);
      });
  }
  public fetchingResponse: boolean = false;
  public fetchingBalance: boolean = false;
  public balance: number;
  public errorMessage: string;
  public cashoutTasks: TableRows<CashoutTaskDto, CashoutTaskStatus> = {
    getPrimaryText: (it: CashoutTaskDto) => {
      const value: Currency = {
        amount: it.amount,
        currencyCode: CurrencyCode.SEK,
      };
      return new ValueListPipe().transform(value, this.localeService.getCurrentLocale());
    },
    getSecondaryText: (it: CashoutTaskDto) => it.reference,
    getStatusBarColor: (it: CashoutTaskDto) => it.statusColor,
    getBackgroundColor: (it: CashoutTaskDto) => it.backgroundColor,
    rowClickFunction: (it: CashoutTaskDto) => this.openTaskInModal(it),
    rows: [],
    hideMobileHeader: false,
  };

  public selectedTask: CashoutTaskDto;
  public submitted: boolean = false;
  public paginationState: PaginationState = {
    page: 1,
    pageSize: 10,
    total: 0,
    numOfItems: 0,
  };
  public accessGranted: boolean = false;
  public checkedAccess: boolean = false;
  public pendingExecutionSum: number = 0;

  public searchQuery: string = '';
  public statusFilters: string[] = [];

  searchQueryChanged: Subject<string> = new Subject<string>();
  merchantHasCashoutTasks: boolean;
  statePushedToHistory: boolean = false;
  modalActive: boolean = false;
  modalSmallActive: boolean = false;
  showCashoutModalContent: boolean = false;
  modalTitle: string;
  modalColor: string;

  columns: ColumnDefinition<CashoutTaskDto, CashoutTaskStatus>[] = [
    {
      text: this.getAliasForTitleInForm(),
      cssClasses: 'medium2 avoid-overflow first-column',
      columnDataTransformer: (cashoutTask: CashoutTaskDto) => cashoutTask.reference,
    },
    {
      text: $localize`:@@cashout.header.lastChanged:Last changed`,
      cssClasses: 'right-align',
      columnDataTransformer: (cashoutTask: CashoutTaskDto) =>
        new DatePipe(this.localeService.getCurrentLocale()).transform(cashoutTask.lastEvent, 'mediumDate'),
    },
    {
      text: $localize`:@@cashout.header.amount:Amount`,
      cssClasses: 'medium2 right-align',
      columnDataTransformer: (cashoutTask: CashoutTaskDto) => {
        const value: Currency = {
          amount: cashoutTask.amount,
          currencyCode: CurrencyCode.SEK,
        };

        return new ValueListPipe().transform(value, this.localeService.getCurrentLocale());
      },
    },
    {
      text: $localize`:@@cashout.header.status:Status`,
      columnDataTransformer: (cashoutTask: CashoutTaskDto) => {
        return cashoutTask.statusText;
      },
      type: 'status',
      filterable: true,
      filterOptions: {
        text: $localize`:@@cashout.header.status:Status`,
        value: 'status',
        activeFilters: [],
        options: [
          {
            headerText: $localize`:@@cashout.statusFilter.options.all.headerText:all`,
            displayText: $localize`:@@cashout.statusFilter.options.all.displayText:Show all`,
            value: [],
          },
          {
            headerText: $localize`:@@cashout.statusFilter.options.initialized.headerText:initiated`,
            displayText: $localize`:@@cashout.statusFilter.options.initialized.displayText:Initiated`,
            value: [CashoutTaskStatus.PENDING_PAYEE_APPROVAL],
          },
          {
            headerText: $localize`:@@cashout.statusFilter.options.certification.headerText:ready for attestation`,
            displayText: $localize`:@@cashout.statusFilter.options.certification.displayText:Ready for attestation`,
            value: [CashoutTaskStatus.PENDING_ADMIN_APPROVAL],
          },
          {
            headerText: $localize`:@@cashout.statusFilter.options.confirmation.headerText:confirm payout`,
            displayText: $localize`:@@cashout.statusFilter.options.confirmation.displayText:Confirm payout`,
            value: [CashoutTaskStatus.PENDING_EXECUTION],
          },
          {
            headerText: $localize`:@@cashout.statusFilter.options.executed.headerText:paid out`,
            displayText: $localize`:@@cashout.statusFilter.options.executed.displayText:Paid out`,
            value: [CashoutTaskStatus.EXECUTED],
          },
          {
            headerText: $localize`:@@cashout.statusFilter.options.cancelled.headerText:cancelled`,
            displayText: $localize`:@@cashout.statusFilter.options.cancelled.displayText:Cancelled`,
            value: [CashoutTaskStatus.CANCELLED],
          },
          {
            headerText: $localize`:@@cashout.statusFilter.options.failed.headerText:payout failed`,
            displayText: $localize`:@@cashout.statusFilter.options.failed.displayText:Payout failed`,
            value: [CashoutTaskStatus.BOUNCED, CashoutTaskStatus.FAILED],
          },
        ],
      },
    },
  ];

  showCashoutTaskCreation: boolean = false;
  showCashoutTaskView: boolean = false;
  showCashoutCancel: boolean = false;
  showCashoutConfirm: boolean = false;
  displayCashoutTaskSentConfirmation: boolean = false;

  displayEmptyState: boolean = false;
  currentEmptyState: EmptyState;
  noCashoutsAtAllEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Smile.png',
    headingText: $localize`:@@cashout.emptyState.headingText:No payouts&nbsp;created`,
    bodyText: $localize`:@@cashout.emptyState.bodyText:There is nothing here to show right now, but as soon as a payout is created it will be displayed here.`,

    ctaButtonText: this.shouldDisplayCreateButton() ? $localize`:@@cashout.emptyState.ctaButtonText:Create payout`: '',
    ctaClickFunction: () => this.create(),
  };
  noCashoutsSearchedEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Monocle.png',
    headingText: $localize`:@@cashout.emptyState.search.headingText:We couldn't find any payout matching the search`,
    bodyText: $localize`:@@cashout.emptyState.search.bodyText:Give it another attempt. Try again.`,
  };
  noCashoutsFilteredEmptyState: EmptyState = {
    imgSrc: 'assets/empty-states/Emoji-Ghost.png',
    headingText: $localize`:@@cashout.emptyState.filter.headingText:There is nothing here yet`,
  };

  quickActions: QuickAction<CashoutTaskDto>[] = [
    {
      text: $localize`:@@cashout.quickActions.resendSms:Send SMS`,
      iconUrl: 'assets/icons/Icon-Send-Black.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (cashoutTask) => {
        return cashoutTask.status === CashoutTaskStatus.PENDING_PAYEE_APPROVAL;
      },
      topBorder: false,
      function: (cashoutTask) => this.resendRequestSms(cashoutTask),
    },
    {
      text: $localize`:@@cashout.quickActions.copyLink:Copy link`,
      iconUrl: 'assets/icons/Icon-Link.svg',
      textColor: '#0A1018',
      disabled: false,
      displayCondition: (cashoutTask) => {
        return cashoutTask.status !== CashoutTaskStatus.EXECUTED;
      },
      topBorder: false,
      function: (cashoutTask) => {
        navigator.clipboard.writeText(
          `https://cashout.zaver.se/${cashoutTask.taskId}`
        );
        // Android 12 has a system toast message when texts are copied to the
        // clipboard. We do not want to make our own toasts in this scenario
        if (this.userAgentService.getAndroidVersion() !== '12') {
          this.toastService.displaySuccess(
            $localize`:@@toast.copyLink.success:The payment link has been copied`
          );
        }
      },
    },
    {
      text: $localize`:@@cashout.quickActions.cancelPayout:Cancel payout`,
      iconUrl: 'assets/icons/Icon-Delete-Cerise.svg',
      textColor: '#DB305E',
      disabled: false,
      displayCondition: (cashoutTask) => {
        return cashoutTask.status === CashoutTaskStatus.PENDING_PAYEE_APPROVAL;
      },
      topBorder: true,
      function: (cashoutTask) => this.cancel(cashoutTask),
    },
  ];

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    if (this.deviceIsMobile()) {
      if (this.statePushedToHistory) {
        this.statePushedToHistory = false;
      }
      if (this.modalActive) {
        this.closeModal();
      } else if (this.modalSmallActive) {
        if (this.showCashoutCancel || this.showCashoutConfirm) {
          this.returnToViewModal();
        } else {
          this.closeSmallModal();
        }
      }
    }
  }

  ngOnInit(): void {
    if (window.location.hash === '#modal') {
      history.back();
    }
    this.accessGranted =
      this.accessControlService.userMay(Permission.GET_CASHOUT_ORDERS) &&
      this.appStateService.merchantHasCashoutAccess();

    this.checkedAccess = true;

    // If we end up here the viewer should be informed that he / she / the company is unauthorized
    // to view Cashout.
    if (!this.accessGranted) {
      return;
    }

    this.refreshTasks();
    this.refreshBalance();
    this.cashoutBalanceService.registerMessageListener(this);
    this.taskService.registerTaskListener(this);

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy(): void {
    this.cashoutBalanceService.unregisterMessageListener(this);
    this.taskService.unregisterTaskListener(this);
    if (this.deviceIsMobile()) {
      if (this.modalActive) {
        this.closeModal();
      } else if (this.modalSmallActive) {
        this.closeSmallModal();
      }
    }
  }

  public closeModalAndRefreshData(): void {
    this.closeModal();
    this.refreshTasks();
    this.refreshBalance();
  }
  public closeSmallModalAndRefreshData(): void {
    this.closeSmallModal();
    this.refreshTasks();
    this.refreshBalance();
  }

  public openTaskInModal(task: CashoutTaskDto): void {
    this.selectedTask = task;
    this.modalColor = this.selectedTask.statusColor;
    this.modalTitle = this.selectedTask.statusText;
    this.showCashoutTaskView = true;
    this.activateModal();
  }

  public closeModal(): void {
    this.deactivateModal();
    this.showCashoutTaskCreation = false;
    this.showCashoutTaskView = false;
    this.selectedTask = null;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      this.statePushedToHistory = false;
      history.back();
    }
  }

  public create(): void {
    this.selectedTask = null;
    this.modalColor = '#00A880';
    this.modalTitle = $localize`:@@cashout.create.modalTitle:New payout`,
    this.showCashoutTaskCreation = true;
    this.activateModal();
  }

  public shouldDisplayCreateButton(): boolean {
    return this.accessControlService.userMay(Permission.CREATE_CASHOUT_ORDER);
  }

  setupQuickActionsForCashoutTask(
    cashoutTask: CashoutTaskDto
  ): TableRow<CashoutTaskDto> {
    return {
      data: cashoutTask,
      quickActions: this.quickActions.filter((action) =>
        action.displayCondition(cashoutTask)
      ),
    };
  }

  resendRequestSms(cashoutTask: CashoutTaskDto): void {
    this.taskService
      .resend(cashoutTask.taskId)
      .subscribe({
        next: () => {
          this.toastService.displaySuccess(
            $localize`:@@toast.resendSms.success:A new SMS has been sent`
          );
        },
        error: () => {
          this.toastService.displayError(
            $localize`:@@toast.resendSms.fail:A new SMS could not be sent`
          );
        }
    });
  }

  searchUpdatedQuery(query: string): void {
    this.searchQueryChanged.next(query);
  }

  searchTasks(query: string): void {
    this.searchQuery = query.trim();
    this.refreshTasks();
  }

  onStatusFilterChange(): void {
    this.refreshTasks();
  }

  cancel(task: CashoutTaskDto): void {
    this.openTaskInModal(task);
    this.displayCancelContent();
  }

  refreshBalance(): void {
    this.fetchingBalance = true;
    this.cashoutBalanceService.getBalance().subscribe(
      (res: CashoutBalanceResponseDto) => {
        this.balance = res.amount;
        this.fetchingBalance = false;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onBalanceUpdated(balanceDto: CashoutBalanceResponseDto): void {
    // check that this balance is for the right merchant
    if (
      !!balanceDto.merchantId &&
      this.appStateService.getCurrentUser().activeRepresentation.company
        .merchantId === balanceDto.merchantId
    ) {
      this.balance = balanceDto.amount;
    }
  }

  onTaskUpdated(updatedDto: CashoutTaskUpdatedDto): void {
    const updatedTask = updatedDto.task;
    const updated = this.setStatusInfoIndividualTask(updatedTask);
    const index = this.cashoutTasks.rows.findIndex(
      (item) => item.data.taskId === updated.taskId
    );

    // update sum of cashout tasks in progress, if needed
    this.pendingExecutionSum = updatedDto.sumAmountTasksPendingExecution;

    if (index === -1) {
      // add new task to top of list
      this.cashoutTasks.rows.unshift({
        data: updated,
        quickActions: this.quickActions,
      });
      this.merchantHasCashoutTasks = true;
    } else {
      // update in place
      const oldTask = this.cashoutTasks[index];
      this.cashoutTasks[index] = updated;
    }
  }

  onPageChange($event): void {
    this.paginationState.page = $event;
    this.refreshTasks();
  }

  onPageSizeChange($event): void {
    this.paginationState.pageSize = $event;
    this.paginationState.page = 1;
    this.refreshTasks();
  }

  refreshTasks(): void {
    this.fetchingResponse = true;
    this.displayEmptyState = false;
    this.taskService
      .getTasks(
        this.searchQuery,
        this.statusFilters,
        null,
        null,
        this.paginationState.page - 1, // page-numbering is zero based on the server
        this.paginationState.pageSize
      )
      .subscribe(
        (res: CashoutTaskListDto) => {
          this.cashoutTasks.rows = res.elements
            .map((task) => this.setStatusInfoIndividualTask(task))
            .map((task) => ({ data: task, quickActions: this.quickActions }));
          this.paginationState.numOfItems = this.cashoutTasks.rows.length;
          this.paginationState.page = res.page + 1; // page-numbering is zero based on the server
          this.paginationState.total = res.total;
          if (this.paginationState.numOfItems === 0) {
            this.setEmptyState();
          } else {
            this.merchantHasCashoutTasks = true;
          }
          this.pendingExecutionSum = res.sumAmountTasksPendingExecution;
          this.fetchingResponse = false;

          this.cashoutTasks.rows = res.elements.map((task: CashoutTaskDto) => {
            return this.setupQuickActionsForCashoutTask(task);
          });
        },
        (err) => {
          this.handleError(err);
          this.fetchingResponse = false;
        }
      );
  }

  setEmptyState(): void {
    if (!this.merchantHasCashoutTasks) {
      this.currentEmptyState = this.noCashoutsAtAllEmptyState;
    } else if (this.statusFilters.length) {
      this.currentEmptyState = this.noCashoutsFilteredEmptyState;
    } else if (this.searchQuery !== '') {
      this.currentEmptyState = this.noCashoutsSearchedEmptyState;
    }
    this.displayEmptyState = true;
  }

  onActiveStatusChange(event: {
    type: string;
    filterOption: FilterOption<CashoutTaskStatus>;
  }): void {
    if (event.type === 'status') {
      this.columns[3].filterOptions.activeFilters = event.filterOption.value;
      this.statusFilters = event.filterOption.value;
      this.paginationState.page = 1;
      this.refreshTasks();
    }
  }

  displayConfirmationText(): void {
    this.closeModal();
    this.submitted = true;
    this.showCashoutConfirm = false;
    this.showCashoutCancel = false;
    this.displayCashoutTaskSentConfirmation = true;
    this.modalColor = '#00A880';
    this.modalTitle = $localize`:@@cashout.requestConfirmation.modalTitle:Request sent`,
    this.activateSmallModal();
  }


  closeSmallModal(): void {
    if (this.submitted) {
      this.merchantHasCashoutTasks = true;
      this.refreshTasks();
      this.refreshBalance();
      this.submitted = false;
    }
    this.deactivateSmallModal();
    this.showCashoutConfirm = false;
    this.showCashoutCancel = false;
    this.displayCashoutTaskSentConfirmation = false;
    this.selectedTask = null;
    if (this.statePushedToHistory && this.deviceIsMobile()) {
      this.statePushedToHistory = false;
      history.back();
    }
  }

  activateModal(): void {
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(null, null, '/se/cashout#modal');
      this.statePushedToHistory = true;
    }
    this.modalActive = true;
  }

  activateSmallModal(): void {
    if (!this.statePushedToHistory && this.deviceIsMobile()) {
      history.pushState(null, null, '/se/cashout#modal');
      this.statePushedToHistory = true;
    }
    this.modalSmallActive = true;
  }

  deactivateModal(): void {
    this.modalActive = false;
  }

  deactivateSmallModal(): void {
    this.modalSmallActive = false;
  }

  updateModalTextAndColor(event: { text: string; color: string }): void {
    if (event.color) {
      this.modalColor = event.color;
    }
    if (event.text) {
      this.modalTitle = event.text;
    }
  }

  displayCancelContent(): void {
    this.showCashoutTaskCreation = false;
    this.showCashoutTaskView = false;
    this.showCashoutConfirm = false;
    this.deactivateModal();
    this.displayCashoutTaskSentConfirmation = false;
    this.modalColor = '#DB305E';
    this.modalTitle = $localize`:@@cashout.cancel.modalTitle:Cancel payout`;
    this.showCashoutCancel = true;
    this.activateSmallModal();
  }

  displayConfirmation(): void {
    this.showCashoutTaskCreation = false;
    this.showCashoutTaskView = false;
    this.showCashoutCancel = false;
    this.deactivateModal();
    this.displayCashoutTaskSentConfirmation = false;
    this.modalColor = '#FAA900';
    this.modalTitle = $localize`:@@cashout.payoutConfirmation.modalTitle:Confirm payout`;
    this.showCashoutConfirm = true;
    this.activateSmallModal();
  }

  setStatusInfo(): void {
    this.cashoutTasks.rows.forEach((cashoutTask: TableRow<CashoutTaskDto>) => {
      let statusInfo: CashoutTaskRepresentation =
        CashoutTaskRepresentations.get(cashoutTask.data.status);

      if (!statusInfo) {
        statusInfo = CashoutTaskRepresentations.get('_default_');
      }

      cashoutTask.data.statusText = statusInfo.friendlyStatus;
      cashoutTask.data.statusColor = statusInfo.textColor;
      cashoutTask.data.backgroundColor = statusInfo.backgroundColor;
    });
  }

  setStatusInfoIndividualTask(task: CashoutTaskDto): CashoutTaskDto {
    let statusInfo: CashoutTaskRepresentation = CashoutTaskRepresentations.get(
      task.status
    );

    if (!statusInfo) {
      statusInfo = CashoutTaskRepresentations.get('_default_');
    }

    task.statusText = statusInfo.friendlyStatus;
    task.statusColor = statusInfo.textColor;
    task.backgroundColor = statusInfo.backgroundColor;

    return task;
  }

  getAliasForTitleInForm(): string {
    const guiCustomizations = this.appStateService.getGuiCustomizations();
    if (guiCustomizations !== null && guiCustomizations.prFormTitleAlias) {
      return guiCustomizations.prFormTitleAlias;
    }

    return $localize`:@@form.header.title:Title`;
  }

  returnToViewModal(): void {
    this.deactivateSmallModal();
    this.showCashoutConfirm = false;
    this.showCashoutCancel = false;
    this.displayCashoutTaskSentConfirmation = false;
    this.openTaskInModal(this.selectedTask);
  }

  deviceIsMobile(): boolean {
    return window.innerWidth <= 520;
  }
}
