import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Currency } from '@core/dto/CurrencyCode';
import { Refund, RefundApprovalResponse } from '@core/dto/refund/Refund';
import { RestBase } from '@core/rest-base';
import { HrefService } from '@core/service/href.service';
import {
  LineItemDomain,
  PaymentCaptureStatus,
} from '@core/service/payment-capture.service';
import { RefundCreationRequest } from '@core/service/refund.service';
import { WidgetContext } from '../components/payment-management/payment-management.interfaces';
import { AccessControlService } from '@core/service/access-control.service';
import { Permission } from '@core/dto/user-details';

@Injectable({
  providedIn: 'root',
})
export class ZFBPaymentCaptureService extends RestBase {
  private token: string;
  private apiUrl: string;
  public context = WidgetContext.ZFB;

  public setToken(token: string) {
    this.token = token;
  }

  public setApiUrl(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  constructor(
    private httpClient: HttpClient,
    hrefService: HrefService,
    private accessControlService: AccessControlService
  ) {
    super(httpClient, hrefService.getApiUrl());
  }

  public getStatus(id: string): Promise<any> {
    return super.get(`/paymentRequest/${id}/status`);
  }

  public captureLineItems(
    paymentId: string,
    amount: Currency,
    lineItems: LineItemDomain[]
  ): Promise<PaymentCaptureStatus> {
    const body = { captureAmount: amount, lineItems: lineItems };
    return super.post(
      `/paymentRequest/${paymentId}/capture`,
      JSON.stringify(body)
    );
  }

  public updateLineItems(
    paymentId: string,
    amount: Currency,
    lineItems: LineItemDomain[],
    status
  ): Promise<PaymentCaptureStatus> {
    const body = {
      captureAmount: amount,
      lineItems: lineItems,
      status: status,
    };
    return super.post(
      `/paymentRequest/${paymentId}/update`,
      JSON.stringify(body)
    );
  }
  public createRefund(refund: RefundCreationRequest): Promise<Refund> {
    return super.post('/refund', JSON.stringify(refund));
  }

  public approve(refundId: string): Promise<RefundApprovalResponse> {
    return super.post('/refund/' + refundId + '/approve', JSON.stringify({}));
  }

  public isEligibleToHandlePaymentRequest(): boolean {
    return this.accessControlService.userMay(Permission.CREATE_CAPTURE);
  }
}
