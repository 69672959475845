import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownListItemv2 } from '../../../../../../../../web-component-library/projects/component-library/src/lib/dropdownv2/dropdownv2.component';
import { FormValidators } from '@shared/validators/validators';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { Market } from '@core/dto/Market';
import { AppStateService } from '@core/service/app-state.service';
import { toCurrency } from '@core/dto/paymentRequest/paymentRequest';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-add-line-item',
  templateUrl: './add-line-item.component.html',
  styleUrls: ['./add-line-item.component.scss'],
})
export class AddLineItemComponent implements OnInit {
  @Output() setView = new EventEmitter<any>();
  @Output() returnToSelection = new EventEmitter();
  @Input() initLineItems = [];

  //Product input field
  inputFieldProductTitle: string = $localize`:@@addLineItem.inputfield.product.title:Product title`;
  inputFieldProductPlaceholder: string = $localize`:@@addLineItem.inputfield.product.placeholder:Product title`;
  inputFieldProductErrorMessage: string = $localize`:@@addLineItem.inputfield.product.errorMessage:Enter the product name (max. 255 characters)`;

  //Article number input field
  inputFieldArticleTitle: string = $localize`:@@addLineItem.inputfield.articleNumber.title:Art. No./Reference`;
  inputFieldArticlePlaceholder: string = $localize`:@@addLineItem.inputfield.articleNumber.placeholder:ex. 9494857`;
  inputFieldArticleErrorMessage: string = $localize`:@@addLineItem.inputfield.articleNumber.errorMessage:Enter the article number (max. 255 characters)`;

  //Unit price input field
  inputFieldUnitTitle: string = $localize`:@@addLineItem.inputfield.unitPrice.title:Unit price (incl. VAT)`;
  inputFieldUnitPlaceholder: string = $localize`:@@addLineItem.inputfield.unitPrice.placeholder:Enter amount`;
  inputFieldUnitErrorMessage: string = $localize`:@@addLineItem.inputfield.unitPrice.errorMessage:Enter unit price (net, incl. VAT, max. 1,000,000)`;

  //Vat & amount/count dropdown
  dropdownVatTitle: string = $localize`:@@addLineItem.dropdown.vat.title:VAT`;
  taxRatePercentErrorMessage: string = $localize`:@@addLineItem.dropdown.vat.errorMessage:Invalid VAT`;
  dropdownCountTitle: string = $localize`:@@addLineItem.dropdown.count.title:Count`;
  countErrorMessage: string = $localize`:@@addLineItem.dropdown.count.errorMessage:Invalid count`;

  totalValueString: string = $localize`:@@addLineItem.totalValue:Total amount incl. VAT`;
  continueButton: string = $localize`:@@addLineItem.button.continue:Continue`;
  backButton: string = $localize`:@@addLineItem.button.back:Back`;

  market: Market = null;
  merchantHasB2B: boolean;

  currencyCode: CurrencyCode = CurrencyCode.SEK;
  showError = false;
  formArray: FormGroup[] = [];

  constructor(
    public localeService: LocaleService,
    private formBuilder: FormBuilder,
    private appStateService: AppStateService
  ) {}

  taxRateOptions: DropdownListItemv2[];

  quantityOptions: DropdownListItemv2[] = this.quantity();

  ngOnInit(): void {
    this.market = this.appStateService.getMarket();
    this.currencyCode = this.appStateService.getDefaultCurrency();
    this.taxRateOptions = this.appStateService.getTaxRateOptions();
    this.merchantHasB2B = this.appStateService.merchantHasB2bPayments();

    this.initFormArray();
  }

  initFormArray(): void {
    if (this.initLineItems.length > 0) {
      this.formArray = this.initLineItems.map((item) => {
        return this.formBuilder.group({
          title: [item.title, [Validators.required, Validators.maxLength(255)]],
          description: [
            item.description,
            [Validators.required, Validators.maxLength(255)],
          ],
          unitPrice: [
            item.unitPrice,
            [
              Validators.required,
              FormValidators.numberMultipleDecimals(
                0,
                this.getMarketMaxValue()
              ),
            ],
          ],
          taxRatePercent: [item.taxRatePercent, Validators.required],
          quantity: [item.quantity, Validators.required],
        });
      });
    } else {
      this.addLineItemForm();
    }
  }

  findOption(option: DropdownListItemv2[], value: any) {
    return option.find((item) => item.value === value);
  }

  addLineItemForm(): void {
    this.formArray = this.formArray.concat(
      this.formBuilder.group({
        title: ['', [Validators.required, Validators.maxLength(255)]],
        description: ['', [Validators.required, Validators.maxLength(255)]],
        unitPrice: [
          '',
          [
            Validators.required,
            FormValidators.numberMultipleDecimals(0, this.getMarketMaxValue()),
          ],
        ],
        taxRatePercent: [this.taxRateOptions[0].value, Validators.required],
        quantity: [this.quantityOptions[0].value, Validators.required],
      })
    );
  }

  setTaxRatePrecent(value, index): void {
    this.formArray[index].controls.taxRatePercent.setValue(value);
  }

  setQuantity(value, index): void {
    this.formArray[index].controls.quantity.setValue(value);
  }

  addLineItem(): void {
    this.showError = false;
    this.addLineItemForm();
  }

  removeLineItem(selectedForm): void {
    this.formArray = this.formArray.filter((form) => form !== selectedForm);
  }

  submit(): void {
    if (this.formArray.find((form) => form.invalid)) {
      this.showError = true;
    } else {
      this.updateUnitPrices();
      const lineItems = this.formArray.map((item) => item.value);
      this.setView.emit(lineItems);
    }
  }

  // Update the unitPrice control's value, applying comma-to-dot conversion
  // and suppressing value change events to avoid unintended interactions.
  private updateUnitPrices(): void {
    this.formArray.forEach((formGroup) => {
      const unitPriceControl = formGroup.get('unitPrice');
      if (unitPriceControl && typeof unitPriceControl.value === 'string') {
        const cleanedValue = unitPriceControl.value
          .replace(/\s/g, '')
          .replace(',', '.');
        unitPriceControl.setValue(cleanedValue, { emitEvent: false });
      }
    });
  }

  quantity(): DropdownListItemv2[] {
    return Array(100)
      .fill('')
      .map((_, i) => ({ label: String(i + 1), value: i + 1 }));
  }

  calculateTotalAmount = (unitPrice: string, quantity: number): Currency => {
    let trimmedUnitPrice = unitPrice.replace(/\s/g, '');

    const unitPriceNumber = Number(trimmedUnitPrice.replace(',', '.'));
    const totalAmount = unitPriceNumber * quantity;

    return toCurrency(totalAmount, this.market);
  };

  getMarketMaxValue(): number {
    if (this.market === Market.SE || this.market === Market.NO) {
      return 1000000; // SEK/NOK
    }

    return 100000; // EUR
  }
}
