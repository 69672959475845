import { Injectable } from '@angular/core';
import { RestBase } from '@core/rest-base';
import { HttpClient } from '@angular/common/http';
import { HrefService } from './href.service';
import {
  GuiCustomizationDto,
  UserDetails,
  CompanyRepresentation,
  CompanyStatus,
  UserSessionUpdateMessage,
  CashoutDetailsProvider,
  MerchantRole,
} from '@core/dto/user-details';
import { Observable, Subject } from 'rxjs';
import { SubTopic, WebsocketListener, WsService } from './ws.service';
import { LoggingService } from './logging.service';
import { Router } from '@angular/router';
import { CurrencyCode } from '@core/dto/CurrencyCode';
import { CompanyRepresentativeRole } from '@core/dto/CompanyRepresentativeRole';
import { Market } from '@core/dto/Market';
import { DropdownListItemv2 } from '../../../../projects/widget/src/components/vat-dropdown/vat-dropdown.component';

@Injectable()
export class AppStateService
  extends RestBase
  implements WebsocketListener<UserSessionUpdateMessage>
{
  private currentUser: UserDetails;
  private userChangeSubject: Subject<UserDetails> = new Subject();
  public user$: Observable<UserDetails> = this.userChangeSubject.asObservable();

  frontEndContextId: string;

  constructor(
    httpClient: HttpClient,
    hrefService: HrefService,
    private wsService: WsService,
    private loggingService: LoggingService,
    private router: Router
  ) {
    super(httpClient, hrefService.getApiUrl());
    this.wsService.registerTopicListener(SubTopic.UserSwitchedProfile, this);
  }

  handleMessage(message: UserSessionUpdateMessage): void {
    // we got a websocket message that our session has changed activeRepresentation
    const changed = this.updateCurrentUser(message.userDetails);
    if (changed) {
      const currentUrl = this.router.url;
      // the activeRepresentation is different from before, we need to reload the view
      // do a dummy navigation first to ensure refresh
      this.router
        .navigate(['/refresh'], { skipLocationChange: true, replaceUrl: false })
        .then(() =>
          this.router.navigate([currentUrl], { skipLocationChange: false })
        )
        .catch(() =>
          this.router.navigate(['/'], { skipLocationChange: false })
        );
    }
  }

  updateCurrentUserTentative(updatedUserDetails: UserDetails) {
    this.currentUser = updatedUserDetails;
  }

  updateCurrentUser(updatedUserDetails: UserDetails): boolean {
    const currentRepId =
      this.currentUser && this.currentUser.activeRepresentation
        ? this.currentUser.activeRepresentation.id
        : null;
    // please note; when logging out updatedUserDetails will be null, and
    // when no profile is selected updatedUserDetails.activeRepresentation is null

    const nextRepId =
      updatedUserDetails && updatedUserDetails.activeRepresentation
        ? updatedUserDetails.activeRepresentation.id
        : null;

    this.currentUser = updatedUserDetails;
    if (updatedUserDetails) {
      this.wsService.activate();
    } else {
      this.wsService.deactivate();
    }
    this.userChangeSubject.next(updatedUserDetails);

    // check if activeRepresentation was updated

    return currentRepId !== nextRepId;
  }

  async refreshCurrentUser(): Promise<UserDetails> {
    const userDetails = await super.get('/user/whoami');
    this.updateCurrentUser(userDetails);
    return userDetails;
  }

  getCurrentUser(): UserDetails {
    return this.currentUser;
  }

  getGuiCustomizations(): GuiCustomizationDto {
    return this.currentUser.activeRepresentation.company.guiCustomizations;
  }

  getDefaultCurrency(): CurrencyCode {
    return this.currentUser.activeRepresentation.company.defaultCurrency;
  }

  hasCurrentUser(): boolean {
    return !!this.currentUser;
  }

  hasSelectedProfile(): boolean {
    return !!this.currentUser && !!this.currentUser.activeRepresentation;
  }

  merchantHasCashoutAccess(): boolean {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.company.cashoutEnabled;
    }
    return false;
  }

  getCashoutMaxAllowedAmount(): number | null {
    return this.currentUser.activeRepresentation.company
      .cashoutMaxAllowedAmount;
  }

  getCashoutDetailsProviders(): CashoutDetailsProvider[] | null {
    return this.currentUser.activeRepresentation.company
      .cashoutDetailsProviders;
  }

  merchantHasPrecheckAccess(): boolean {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.company.precheckEnabled;
    }
    return false;
  }

  merchantHasStoreAccess(): boolean {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.company.storeEnabled;
    }
    return false;
  }

  merchantHasRefundAccess(): boolean {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.company.refundsEnabled;
    }
    return false;
  }

  merchantHasB2bPayments(): boolean {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.company.b2bPaymentsEnabled;
    }
  }

  merchantHasLineItemPayments(): boolean {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.company
        .lineItemPaymentsEnabled;
    }
  }

  repHasStoreAccess(): boolean {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.mayViewStores;
    }
    return false;
  }

  merchantHasAutoRegistration(): boolean {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.company.hasAutoRegistration;
    }
    return false;
  }

  merchantHasAutomaticRefundEnabled(): boolean {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.company.automaticRefund;
    }
    return false;
  }

  allowNonIdentifiedUsersForRoles(): CompanyRepresentativeRole[] {
    return this.getActiveRepresentation().company
      .allowNonIdentifiedUsersForRoles;
  }

  getRole(): CompanyRepresentativeRole {
    return this.getActiveRepresentation()
      ? this.getActiveRepresentation().role
      : undefined;
  }

  hasStatus(status: CompanyStatus): boolean {
    const representation = this.getActiveRepresentation();

    return (
      representation &&
      representation.company &&
      representation.company.status &&
      representation.company.status.indexOf(status) >= 0
    );
  }

  public isApproved(): boolean {
    return this.hasStatus(CompanyStatus.APPROVED);
  }

  public isRejected(): boolean {
    return this.hasStatus(CompanyStatus.UNAPPROVED);
  }

  public isSignedUp(): boolean {
    return this.hasStatus(CompanyStatus.SIGNED_UP);
  }

  public getActiveRepresentation(): CompanyRepresentation | undefined {
    return this.currentUser ? this.currentUser.activeRepresentation : undefined;
  }

  public getAvailableRepresentations(): CompanyRepresentation[] {
    return this.currentUser ? this.currentUser.availableRepresentations : [];
  }

  public clear() {
    this.updateCurrentUser(null);
  }

  public renewContextId() {
    this.loggingService.log('updating frontendContext');
    this.frontEndContextId = 'zfb-' + this.makeId(10);
  }

  makeId(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public getContextID() {
    return this.frontEndContextId ? this.frontEndContextId : '';
  }

  public getMerchantRoles(): MerchantRole[] {
    if (this.hasSelectedProfile()) {
      return this.currentUser.activeRepresentation.company.roles.sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
    }
    return [];
  }

  public getMarket(): Market {
    return this.currentUser
      ? this.currentUser.activeRepresentation.company.market
      : undefined;
  }

  public getSupportedCountryCodes(): number[] {
    if (this.hasSelectedProfile()) {
      let supportedCountryCodes =
        this.currentUser.activeRepresentation.company.supportedCountryCodes;
      if (this.getMarket() === Market.NO) {
        supportedCountryCodes.push(46);
      }

      return supportedCountryCodes;
    }
    return [];
  }

  public getTaxRateOptions(): DropdownListItemv2[] {
    switch (this.getMarket()) {
      case Market.NO:
        return [
          {
            label: '25%',
            value: 25,
          },
          {
            label: '15%',
            value: 15,
          },
          {
            label: '12%',
            value: 12,
          },
          {
            label: '0%',
            value: 0,
          },
        ];
      case Market.SE:
        return [
          {
            label: '25%',
            value: 25,
          },
          {
            label: '12%',
            value: 12,
          },
          {
            label: '6%',
            value: 6,
          },
          {
            label: '0%',
            value: 0,
          },
        ];
      case Market.AT:
        return [
          {
            label: '20%',
            value: 20,
          },
          {
            label: '13%',
            value: 13,
          },
          {
            label: '10%',
            value: 10,
          },
          {
            label: '0%',
            value: 0,
          },
        ];
      case Market.DE:
        return [
          {
            label: '19%',
            value: 19,
          },
          {
            label: '7%',
            value: 7,
          },
          {
            label: '0%',
            value: 0,
          },
        ];
      case Market.FI:
        return [
          {
            label: '24%',
            value: 24,
          },
          {
            label: '14%',
            value: 14,
          },
          {
            label: '10%',
            value: 10,
          },
          {
            label: '0%',
            value: 0,
          },
        ];
      default:
        let taxRateOptions: DropdownListItemv2[] = [];
        let percent: number = 35;

        taxRateOptions.push({
          label:
            '-% (market ' + this.getMarket() + ' has no VAT configured yet)',
          value: null,
        });

        for (; percent > 0; percent--) {
          taxRateOptions.push({
            label: percent + '%',
            value: percent,
          });
        }
        return taxRateOptions;
    }
  }
}
