import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@core/base.component';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { PrecheckLimits } from '@core/dto/precheck/PrecheckLimits';
import { PrecheckRequest } from '@core/dto/precheck/PrecheckRequest';
import { availableCountryCodes, setCountryFlag } from '@core/select-country';
import { AppStateService } from '@core/service/app-state.service';
import { AuthenticationService } from '@core/service/authentication.service';
import { LocaleService } from '@core/service/locale.service';
import { PrecheckService } from '@core/service/precheck.service';
import { ToastMessageService } from '@core/service/toast-message.service';
import { FormValidators } from '@shared/validators/validators';
import { Subscription } from 'rxjs';
import {
  formatPhoneNumber,
  phoneNumberValidator,
} from '../../../../../../web-component-library/projects/component-library/src/common/phone-number-validator.directive';
import { Item } from '../../../../../../web-component-library/projects/component-library/src/public-api';

@Component({
  selector: 'app-create-precheck',
  templateUrl: './create-precheck.component.html',
  styleUrls: ['./create-precheck.component.scss'],
})
export class CreatePrecheckComponent extends BaseComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private appStateService: AppStateService,
    private precheckService: PrecheckService,
    private toastService: ToastMessageService,
    private localeService: LocaleService,
    protected auth: AuthenticationService
  ) {
    super(auth);
  }

  @Input() merchantPrecheckLimits: PrecheckLimits;

  merchantDefaultCurrency: CurrencyCode;

  showError: boolean = false;
  creatingPrecheck: boolean = false;

  amountPlaceholder: string;
  form: FormGroup;
  readonly AVAILABLECOUNTRYCODES = availableCountryCodes;
  selectedcountry: Item = this.AVAILABLECOUNTRYCODES[5];
  formSubscription: Subscription;

  @Output() created = new EventEmitter();

  ngOnInit(): void {
    this.merchantDefaultCurrency = this.appStateService.getDefaultCurrency();

    this.amountPlaceholder = `${new CurrencyPipe(
      this.localeService.getCurrentLocale()
    ).transform(
      this.merchantPrecheckLimits.min.amount,
      this.merchantDefaultCurrency
    )} - ${new CurrencyPipe(this.localeService.getCurrentLocale()).transform(
      this.merchantPrecheckLimits.max.amount,
      this.merchantDefaultCurrency
    )}`;

    this.setup();
  }

  async setup(): Promise<void> {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      phone: [
        '+49 ',
        [
          Validators.required,
          phoneNumberValidator(this.appStateService.getSupportedCountryCodes()),
        ],
      ],
      amount: [
        '',
        [
          Validators.required,
          FormValidators.number(
            this.merchantPrecheckLimits.min.amount,
            this.merchantPrecheckLimits.max.amount
          ),
        ],
      ],
    });
    this.formSubscription = this.form.controls.phone.valueChanges.subscribe(
      (value) => {
        this.selectedcountry = setCountryFlag(value, this.selectedcountry);
      }
    );
  }

  setCountry(selected) {
    this.form.controls.phone.setValue(selected.value);
  }

  submit(): void {
    if (this.form.valid) {
      this.errorMessage = null;
      const value: Currency = {
        amount: this.form.value.amount.replace(/\s/g, '').replace(',', '.'),
        currencyCode: this.merchantDefaultCurrency,
      };

      const precheckToCreate: PrecheckRequest = new PrecheckRequest(
        null,
        this.form.value.name.trim(),
        formatPhoneNumber(this.form.value.phone),
        value
      );

      this.createPrecheck(precheckToCreate);
    } else {
      this.showError = true;
    }
  }

  async createPrecheck(precheckReqeust: PrecheckRequest): Promise<void> {
    this.creatingPrecheck = true;
    try {
      await this.precheckService.create(precheckReqeust);
      this.toastService.displaySuccess(
        $localize`:@@toast.precheck.success:Precheck successfully initiated`
      );
      this.created.emit();
    } catch (error) {
      this.handleError(error);
    }
    this.creatingPrecheck = false;
  }

  getAmountErrorMessage(): string {
    return $localize`:@@precheck.de.amount.errorMessage:Enter an amount between ${new CurrencyPipe(
      this.localeService.getCurrentLocale()
    ).transform(
      this.merchantPrecheckLimits.min.amount,
      this.merchantDefaultCurrency
    )} – ${new CurrencyPipe(this.localeService.getCurrentLocale()).transform(
      this.merchantPrecheckLimits.max.amount,
      this.merchantDefaultCurrency
    )}`;
  }

  getPhoneErrorMessage(): string {
    const errors = this.form.controls.phone.errors;
    if (!errors) {
      return null;
    }

    if (errors['required']) {
      return $localize`:@@precheck.de.mobileNumber.errorMessage.required:Please enter a mobile number`;
    } else if (errors['invalidFormat']) {
      return $localize`:@@precheck.de.mobileNumber.errorMessage.invalidFormat:Please enter a mobile number (+***********)`;
    } else if (errors['invalidNumber']) {
      return $localize`:@@precheck.de.mobileNumber.errorMessage.invalidNumber:Please enter a valid mobile number`;
    } else if (errors['unsupportedCountryCode']) {
      return $localize`:@@precheck.de.mobileNumber.errorMessage.unsupportedCountryCode:The country code entered is not supported`;
    }

    return null;
  }
}
