import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FilterOption, FilterOptions } from '../table/table.component';

@Component({
  selector: 'app-table-filter-dropdown',
  templateUrl: './table-filter-dropdown.component.html',
  styleUrls: ['./table-filter-dropdown.component.css'],
})
export class TableFilterDropdownComponent<T> implements OnInit {
  constructor(private elementRef: ElementRef) {}

  @Input() filterOptions: FilterOptions<T>;

  @Output() filterClicked = new EventEmitter<FilterOption<T>>();

  showList = false;
  selectedOption;

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.showList) {
        this.showList = false;
      }
    }
  }

  ngOnInit(): void {
    const { options, activeFilters } = this.filterOptions;

    this.selectedOption = options.find(({ value }) =>
      value.length === activeFilters.length &&
      value.every((val, idx) => val === activeFilters[idx])
    ) || options[0];
  }

  toggleList() {
    this.showList = !this.showList;
  }

  onClickFilter(option) {
    this.selectedOption = option;
    this.toggleList();
    this.filterClicked.emit(option);
  }
}
