<div *ngIf="initialSelection && merchantHasB2B" id="initial-selection">
  <div class="create-button" (click)="selectConsumer()">
    <img
      src="assets/icons/Icon-User-Single-Black.svg"
      alt=""
      width="20px"
      height="20px"
    />
    <span>
      <span i18n="@@createPaymentRequest.initial-selection.consumer"
        >Consumer</span
      >
      <span class="initial-selection-text">(B2C)</span>
    </span>
  </div>
  <div class="create-button" (click)="selectCompany()">
    <img src="assets/icons/Icon-Business.svg" alt="" />
    <span>
      <span i18n="@@createPaymentRequest.initial-selection.company"
        >Business</span
      >
      <span class="initial-selection-text">(B2B)</span>
    </span>
  </div>
</div>

<div *ngIf="creation" class="create-container">
  <!-- This is where the create components go -->
  <ng-container
    *ngIf="!merchantHasLineItems && (market === 'NO' || market === 'SE')"
  >
    <app-create-payment-request-consumer
      (onDone)="onDone.emit($event)"
      (onClose)="onClose.emit($event)"
      [class.hide]="selectedCompany"
      [defaultCurrency]="merchantDefaultCurrency"
      (returnToSelection)="openSelection()"
    ></app-create-payment-request-consumer>

    <app-create-payment-request-company
      (onDone)="onDone.emit($event)"
      (onClose)="onClose.emit($event)"
      [class.hide]="selectedConsumer"
      [defaultCurrency]="merchantDefaultCurrency"
      (returnToSelection)="openSelection()"
    ></app-create-payment-request-company>
  </ng-container>

  <ng-container
    *ngIf="
      market === 'DE' ||
      market === 'AT' ||
      market === 'FI' ||
      (merchantHasLineItems && (market === 'NO' || market === 'SE'))
    "
  >
    <app-de-create-payment-request-consumer
      (onDone)="onDone.emit($event)"
      (onClose)="onClose.emit($event)"
      [class.hide]="selectedCompany"
      (onClose)="onClose.emit($event)"
      (returnToSelection)="openSelection()"
    ></app-de-create-payment-request-consumer>

    <app-create-payment-request-company
      *ngIf="market === 'SE'"
      (onDone)="onDone.emit($event)"
      (onClose)="onClose.emit($event)"
      [class.hide]="selectedConsumer"
      [defaultCurrency]="merchantDefaultCurrency"
      (returnToSelection)="openSelection()"
    ></app-create-payment-request-company>
  </ng-container>
</div>
