import { Currency, CurrencyCode } from '../CurrencyCode';
import { Market } from '../Market';

export enum DePaymentRequestView {
  ADD_LINE_ITEM = 'ADD_LINE_ITEM',
  CREATE_PAMYMENT_REQUEST = 'CREATE_PAMYMENT_REQUEST',
}

export interface LineItem {
  id: string;
  title: string;
  description: string;
  unitPrice: number;
  taxRatePercent: number;
  quantity: number;
  taxAmount: number;
  totalAmount: number;
}

export const calculateTax = (
  lineItems: LineItem[],
  market: Market
): Currency => {
  return toCurrency(
    lineItems.reduce(
      (acc: number, item: LineItem) =>
        acc +
        Number(
          (
            getUnitPriceExcludingTax(item.unitPrice, item.taxRatePercent) *
            (item.taxRatePercent / 100)
          ).toFixed(2)
        ) *
          item.quantity,
      0
    ),
    market
  );
};

export const calculateTotalArticleAmountIncludingTax = (
  lineItems: LineItem[],
  market: Market
): Currency => {
  return toCurrency(
    lineItems.reduce(
      (acc: number, item: LineItem) =>
        acc + Number((item.unitPrice * item.quantity).toFixed(2)),
      0
    ),
    market
  );
};

export const getUnitPriceExcludingTax = (
  unitPrice: number,
  taxRatePercent: number
): number => {
  return unitPrice / ((100 + taxRatePercent) / 100);
};

export const toTwoDecimals = (amount: number): number =>
  Math.round(amount * 100) / 100;

export function toCurrency(amount: number, market: Market): Currency {
  let currencyCodeByMarket: CurrencyCode;

  switch (market) {
    case Market.SE:
      currencyCodeByMarket = CurrencyCode.SEK;
      break;
    case Market.NO:
      currencyCodeByMarket = CurrencyCode.NOK;
      break;
    case Market.AT:
    case Market.DE:
    case Market.FI:
      currencyCodeByMarket = CurrencyCode.EUR;
      break;

    default:
      currencyCodeByMarket = CurrencyCode.EUR;
      break;
  }

  return {
    amount,
    currencyCode: currencyCodeByMarket,
  };
}
