import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
} from '@angular/core';
import {
  CompanyStatus,
  Permission,
  UserDetails,
  UserSessionUpdateMessage,
} from '@core/dto/user-details';
import { AppStateService } from '@core/service/app-state.service';
import { AccessControlService } from '@core/service/access-control.service';
import {
  WsService,
  SubTopic,
  WebsocketListener,
} from '@core/service/ws.service';
import { CustomizationService } from '@core/service/customization.service';
import { CompanyRepresentationRoleColorRepresentations } from '@assets/language/RoleColorRepresentation';
import { NavigationEnd, Router } from '@angular/router';
import { ColorService } from '@core/service/color.service';
import { filter } from 'rxjs/operators';
import { LocaleService } from '@core/service/locale.service';
import { LocaleOption } from '../../../../../web-component-library/projects/component-library/src/public-api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  implements OnChanges, WebsocketListener<UserSessionUpdateMessage>
{
  constructor(
    private appStateService: AppStateService,
    private access: AccessControlService,
    private wsService: WsService,
    private customizationService: CustomizationService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private localeService: LocaleService,
    public colorService: ColorService
  ) {
    this.wsService.registerTopicListener(SubTopic.UserSwitchedProfile, this);
    router.events.pipe(filter((e) => e instanceof NavigationEnd));
  }

  @Input() user: UserDetails;
  @Output() logout = new EventEmitter();

  modalActive: boolean = false;
  modalSmallActive: boolean = false;
  modalText: string;
  logoutModalActive: boolean = false;
  profileSelectModalActive: boolean = false;
  profileChangedModalActive: boolean = false;

  storeAliasName: string = 'Hej';

  public menuShowing: string = '';
  showAdminSubMenu: boolean = false;
  showMobileMenu: boolean = false;
  showProfileMenu: boolean = false;
  mobileMainMenu: boolean = false;
  mobileProfileMenu: boolean = false;

  locales: LocaleOption[] = this.localeService.getLocales();
  activeLocale: string = this.localeService.getCurrentLocale();

  readonly NO_ROUTE = '';

  @HostListener('document:click', ['$event.target'])
  public onClick(target: Event): void {
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      if (this.showProfileMenu) {
        this.showProfileMenu = false;
        this.renderer.removeClass(document.body, 'disable-overflow-body');
      }
    }
  }

  handleMessage(message: UserSessionUpdateMessage): void {
    if (!this.user || !this.user.activeRepresentation) {
      // not logged in
      return;
    }
    if (
      this.user.activeRepresentation &&
      message.userDetails &&
      message.userDetails.activeRepresentation &&
      this.user.activeRepresentation.id ===
        message.userDetails.activeRepresentation.id
    ) {
      // same user => do nothing
      return;
    }
    if (message.userDetails === null) {
      // The user is being logged out
      return;
    }

    this.notifyAboutProfileUpdate();
  }

  ngOnChanges(): void {
    if (
      this.user &&
      this.user.activeRepresentation &&
      this.user.activeRepresentation.role
    ) {
      this.storeAliasName = this.customizationService.getStoreAlias(
        true,
        false,
        false,
        true
      );
    }
  }

  getLogoPath(): string {
    return 'assets/logo/zaver_logotype_white.svg';
  }

  isLoggedIn(): boolean {
    return !!this.user;
  }

  hasSelectedProfile(): boolean {
    return !!this.user && !!this.user.activeRepresentation;
  }

  hasMultipleApprovedProfiles(): boolean {
    return (
      !!this.user &&
      this.user.availableRepresentations.filter((rep) =>
        rep.company.status.find((status) => status === CompanyStatus.APPROVED)
      ).length > 1
    );
  }

  isApproved(): boolean {
    return this.appStateService.isApproved();
  }

  navigateOnOpenAdminMenu(): string {
    if (this.mayViewUsers()) {
      return '/admin/users';
    }
    if (this.mayUseStores()) {
      return '/admin/stores';
    }
    if (this.mayViewRefunds()) {
      return '/admin/refunds';
    }
    if (this.mayViewReport()) {
      return '/admin/report';
    }
    if (this.mayViewCompanyGeneralInfo()) {
      return '/admin/company';
    }
    if (this.mayViewZfbMerchantSettings()) {
      return '/admin/zfbMerchantSettings';
    }
    return this.NO_ROUTE;
  }

  mayDisplayAdminMenu(): boolean {
    return this.navigateOnOpenAdminMenu() !== this.NO_ROUTE;
  }

  mayViewUsers(): boolean {
    return this.access.userMay(Permission.GET_USERS);
  }

  mayUseStores(): boolean {
    return (
      this.access.userMay(Permission.GET_STORES) &&
      this.appStateService.merchantHasStoreAccess()
    );
  }

  mayViewCompanyGeneralInfo(): boolean {
    return this.access.userMay(Permission.GET_COMPANY_INFORMATION);
  }

  mayViewZfbMerchantSettings(): boolean {
    return this.access.userMay(Permission.GET_ZFB_MERCHANT_SETTINGS);
  }

  mayViewCashout(): boolean {
    return (
      this.access.userMay(Permission.GET_CASHOUT_ORDERS) &&
      this.appStateService.merchantHasCashoutAccess()
    );
  }

  mayViewReport(): boolean {
    return this.access.userMay(Permission.GET_REPORTS);
  }

  mayViewPrecheck(): boolean {
    return (
      this.access.userMay(Permission.CREATE_PRECHECK) &&
      this.appStateService.merchantHasPrecheckAccess()
    );
  }

  mayViewRefunds(): boolean {
    return (
      this.access.userMay(Permission.GET_REFUNDS) &&
      this.appStateService.merchantHasRefundAccess()
    );
  }

  closeModal(): void {
    this.profileSelectModalActive = false;
    this.deactivateModal();
  }
  closeSmallModal(): void {
    this.logoutModalActive = false;
    this.profileChangedModalActive = false;
    this.deactivateSmallModal();
  }

  openLogoutModalMobile(): void {
    this.toggleMobileMenu();
    this.openLogoutModal();
  }

  openLogoutModal(): void {
    this.modalText = $localize`:@@header.modal.title.logout:Sign out`;
    this.logoutModalActive = true;
    this.closeMobileMenu();
    this.closeProfileMenu();
    this.activateSmallModal();
  }

  openProfileSelectModal(): void {
    this.modalText = $localize`:@@header.modal.title.profileSelect:Select profile`;
    this.profileSelectModalActive = true;
    this.closeMobileMenu();
    this.closeProfileMenu();
    this.activateModal();
  }

  openProfileChangedModal(): void {
    this.modalText = '';
    this.profileChangedModalActive = true;
    this.activateSmallModal();
  }

  activateModal(): void {
    this.modalActive = true;
  }
  activateSmallModal(): void {
    this.modalSmallActive = true;
  }

  deactivateModal(): void {
    this.modalActive = false;
  }
  deactivateSmallModal(): void {
    this.modalSmallActive = false;
  }

  logoutUser(): void {
    this.closeSmallModal();
    this.closeModal();
    this.logout.emit();
  }

  // hooked up to event
  onProfileSelected(): void {
    this.closeModal();
    this.closeMobileMenu();
    this.closeProfileMenu();
  }

  notifyAboutProfileUpdate(): void {
    this.closeModal();
    this.closeMobileMenu();
    this.openProfileChangedModal();
  }

  contactLink(): string {
    switch (this.localeService.getCurrentLocale()) {
      case 'de':
      case 'de-AT':
        return 'https://zaver.com/de/customer-support';
      case 'sv':
        return 'https://zaver.com/sv/customer-support';
      default:
        return 'https://zaver.com/customer-support';
    }
  }

  toggleAdminList(): void {
    this.showAdminSubMenu = !this.showAdminSubMenu;
  }

  toggleMobileMenu(): void {
    if (this.showMobileMenu && this.mobileProfileMenu) {
      this.mobileMainMenu = true;
      this.mobileProfileMenu = false;
      this.renderer.addClass(document.body, 'disable-overflow-body');
    } else if (this.showMobileMenu && this.mobileMainMenu) {
      this.showMobileMenu = false;
      this.mobileMainMenu = false;
      this.mobileProfileMenu = false;
      this.renderer.removeClass(document.body, 'disable-overflow-body');
    } else if (!this.showMobileMenu) {
      this.showMobileMenu = true;
      this.mobileMainMenu = true;
      this.renderer.addClass(document.body, 'disable-overflow-body');
    }
  }

  closeMobileMenu(): void {
    this.showMobileMenu = false;
    this.mobileMainMenu = false;
    this.mobileProfileMenu = false;
    this.renderer.removeClass(document.body, 'disable-overflow-body');
  }

  closeProfileMenu(): void {
    this.showProfileMenu = false;
    this.renderer.removeClass(document.body, 'disable-overflow-body');
  }

  toggleProfileMenu(): void {
    this.showProfileMenu = !this.showProfileMenu;
    this.mobileProfileMenu = this.showProfileMenu;

    if (this.showProfileMenu && !this.showMobileMenu) {
      this.showMobileMenu = true;
      this.renderer.addClass(document.body, 'disable-overflow-body');
    } else if (this.showMobileMenu && this.showProfileMenu) {
      this.mobileMainMenu = false;
      this.renderer.removeClass(document.body, 'disable-overflow-body');
    } else if (!this.showProfileMenu) {
      this.showMobileMenu = false;
      this.renderer.removeClass(document.body, 'disable-overflow-body');
    }
  }

  logoClicked(): void {
    this.showAdminSubMenu = false;
    this.showMobileMenu = false;
    this.showProfileMenu = false;
    this.mobileMainMenu = false;
    this.mobileProfileMenu = false;
    this.renderer.removeClass(document.body, 'disable-overflow-body');
  }

  roleColor(): string {
    let colorInfo = CompanyRepresentationRoleColorRepresentations.get(
      this.user.activeRepresentation.role.color
    );
    if (!colorInfo) {
      colorInfo =
        CompanyRepresentationRoleColorRepresentations.get('_default_');
    }
    return colorInfo.hexColor;
  }

  localeChanged(option: LocaleOption): void {
    if (this.localeService.getCurrentLocale() === option.locale) {
      return;
    }

    this.localeService.setCurrentLocale(option.locale);
    location.href = '/' + option.subPath + this.router.url;
  }
}
